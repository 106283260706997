import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

export interface IPassword {
  password: string;
  passwordConfirmation: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  apiUrl: string = environment.apiUrl;
  private headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  public  TOKEN_KEY: string = "currentUser";

  constructor(private http: HttpClient, public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(this.TOKEN_KEY)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setCurrentUser(user: any){
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.apiUrl}/auth/signin`, { email, password }, { headers: this.headers });
  }

  getUser(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  isLoggedIn(): boolean {
    return this.currentUserValue !== null;
  }

  goToSign() {
    //this.router.navigate(['/sign']);
    this.router.navigateByUrl('/sign');
  }

  recoverPassword(email: string) {
    return this.http.post<any>(`${this.apiUrl}/auth/recover-password`, { email });
  }

  changePassword(body, token: string) {
    return this.http.patch<any>(`${this.apiUrl}/auth/reset-password/${token}`, body, { headers: this.headers });
  }

  confirmEmail(token: string) {
    return this.http.patch<any>(`${this.apiUrl}/auth/verify-email/${token}`, { headers: this.headers });
  }

  resendeVerifyEmail(email: string) {
    return this.http.post<any>(`${this.apiUrl}/auth/resend-verify-email`, { email });
  }
 
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.TOKEN_KEY);
    this.currentUserSubject.next(null);
  }
}
