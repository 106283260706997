import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { activities } from 'src/app/models/activities'
import { banks } from 'src/app/models/banks'

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() type: string
  lista: any[]
  // { name: string; }[] || { name: string; code: number; fullName: string }[];
  constructor(public modalController: ModalController) {}

  ngOnInit() {
    if (this.type === 'atividades') {
      this.lista = activities
    } else {
      this.lista = banks
    }
  }

  onSearchChange(e) {
    if (this.type === 'atividades') {
      this.lista = activities
    } else {
      this.lista = banks
    }
    const val = e.detail.value

    if (val && val.trim() != '') {
      this.lista = this.lista.filter(item => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
      })
    }
  }

  async select(item) {
    if (this.type === 'atividades') {
      await this.modalController.dismiss({
        value: item.name
      })
    } else {
      await this.modalController.dismiss({
        value: item.code + ' - ' + item.name
      })
    }
  }

  async closeModal() {
    await this.modalController.dismiss('cancel')
  }
}
