export const environment = {
  production: false,
  apiUrl: 'https://api.cliente-dev.kikaipay.com.br/api/v1',
  apiCnpjConsultUrl: 'https://www.receitaws.com.br/v1/cnpj',
  apiCnpjToken: '29785843ce5bf87dfdcc771c4e18255d9b60a54ca5bddd0446e81389a030cd9c',
  enableDebug: false,
  apiAbstraction: 'https://ipgeolocation.abstractapi.com/v1/?api_key=',
  abstractapi: 'e630b1d5aefc4bfcb1133e97e1cd5e29'
};

