import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCnpj' })
export class FormatCnpjPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return '';
    }

    value = this.clearStrings(value);

    const first = value.substr(0, 2);
    const second = value.substr(2, 3);
    const third = value.substr(5, 3);
    const fourth = value.substr(8, 4);
    const fifith = value.substr(12, 2);

    const formattedCnpj = `${first}.${second}.${third}/${fourth}-${fifith}`;

    return formattedCnpj;
  }

  clearStrings(txt?: string | number) {
    return txt.toString().replace(/[^a-zA-Z0-9]/g, '');
  }
}
