import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Company } from '../../../node_modules/kikai-model/dist/interface/company/Company';
import { HttpClient } from '@angular/common/http';
import { JwtInfoService } from 'src/app/services/jwt-info.service';
import { User } from 'src/app/interface/user';

/* Old Imports */
import { NFInvalida } from 'src/domain/errors/NFInvalida.error';
// import firebase from 'firebase/app';
// import { Company } from './../pages/cadastro-inicial/shared/company';
import 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { Antecipacao } from '../models/antecipacao';
// import { Company } from '../models/company';
import { Prospect } from '../models/prospect';
import { Status_Internal, Status_InternalNF } from '../models/status.enum';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  public currentProspect: Partial<Prospect> = {
    uid: ''
  };

  public currentCompany: Partial<Company> = {
    // uid: ''
  };

  public companySubject = new BehaviorSubject<Partial<Company>>(this.currentCompany);
  public prospectSubject = new BehaviorSubject<Partial<Prospect>>(this.currentProspect);
  private onSigningUp = false;
  urlBase: string = environment.apiUrl;

  constructor(private readonly router: Router, private http: HttpClient) {}

  ngOnInit() {
    //this.getInfoToken = this.jwthelp.jwtTokenCode();
  }

  async getCompanys(id: string, filter?: { status?: string }): Promise<any> {
    console.log(id);
    //const { id } = this.jwtPayload.jwtTokenCode;
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    // const getCompanys = firebase.functions().httpsCallable('getCompanys');
    // const response = (await getCompanys(filter || {})).data;
    // this.handleResponseError(response);
    // return response.data.map(this.adaptCompanyStatus);
    return this.http.get<Company>(`${this.urlBase}/company/${id}/list`);
  }
  async getOneProspect() {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    // const getOneProspect = firebase.functions().httpsCallable('getOneProspect')
    // const response = (await getOneProspect()).data
    // this.handleResponseError(response)
    // return response.data
  }

  // async getOneCompany(doc: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const getOneCompany = firebase.functions().httpsCallable('getOneCompany');
  //   const response = (
  //     await getOneCompany({
  //       companyId: doc
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response.data as Company;
  // }

  // async getOneAntecipacao(doc: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const getOneAntecipacao = firebase.functions().httpsCallable('getOneAntecipacao');
  //   const response = (
  //     await getOneAntecipacao({
  //       antecipacaoId: doc
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response.data;
  // }

  // handleResponseError(response) {
  //   if (response.status === 'error') {
  //     console.log(response);

  //     this.responseError(response);
  //   }
  // }

  // private adaptCompanyStatus(company: Company) {
  //   return {
  //     ...company,
  //     stInternal: Status_Internal[company.statusInternal.toString()]
  //   };
  // }

  /* Old Code
  async getCompanys(filter?: { status?: string }): Promise<Company[]> {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const getCompanys = firebase.functions().httpsCallable('getCompanys');
    const response = (await getCompanys(filter || {})).data;
    this.handleResponseError(response);
    return response.data.map(this.adaptCompanyStatus);
  }
  */

  // async verifyCNPJ(cnpj: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const verifyCNPJ = firebase.functions().httpsCallable('verifyCNPJ');
  //   const response = await verifyCNPJ({
  //     cnpj
  //   });
  // this.handleResponseError(response);
  // return response.data.retorno;
  //}

  // async verifyNF(companyId: string, nf: any): Promise<boolean> {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const verifyNF = firebase.functions().httpsCallable('verifyNF');
  //   const response = (
  //     await verifyNF({
  //       companyId,
  //       nf
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   if (response.data.errorMessage) {
  //     throw new NFInvalida(response.data.errorMessage);
  //   }

  //   return response.data.isValid;
  // }

  // async getPagamentos(companyId: string) {
  //   // firebase.functions().useFunctionsEmulator('http://localhost:5001')
  //   const getPagamentos = firebase.functions().httpsCallable('getPagamentos');
  //   const response = (
  //     await getPagamentos({
  //       companyId
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response.data;
  // }

  // async getNFs(): Promise<Antecipacao[]> {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const getNFs = firebase.functions().httpsCallable('getNFs');
  //   const response = (await getNFs({})).data;
  //   this.handleResponseError(response);
  //   return response.data.map(this.adaptAntecipacaoStatus);
  // }

  // private adaptAntecipacaoStatus(antecipacao: Omit<Antecipacao, 'stInternal'>): Antecipacao {
  //   return {
  //     ...antecipacao,
  //     stInternal: Status_InternalNF[antecipacao.statusInternal]
  //   };
  // }

  // async calcTaxas(nf: Partial<Antecipacao>, dataAntecipacao: any) {
  //   // firebase.functions().useEmulator("http://localhost", 5001);
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const calcTaxas = firebase.functions().httpsCallable('calcTaxas');
  //   const response = (
  //     await calcTaxas({
  //       dataAntecipacao,
  //       nf
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response;
  // }

  // setCurrentCompany(uid: string) {
  //   this.currentCompany.uid = uid;
  //   this.companySubject.next(this.currentCompany);
  // }

  // setCurrentProspect(uid: string) {
  //   this.currentProspect.uid = uid;
  //   this.prospectSubject.next(this.currentProspect);
  // }

  // async addCompany(company: Partial<Company>) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const addCompany = firebase.functions().httpsCallable('addCompany');
  //   const response = (
  //     await addCompany({
  //       company: company
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response;
  // }

  // async changeCompanyStatus(companyId: string, statusInternal: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const changeCompanyStatus = firebase.functions().httpsCallable('changeCompanyStatus');
  //   const response = (
  //     await changeCompanyStatus({
  //       companyId,
  //       statusInternal
  //     })
  //   ).data;
  //   this.handleResponseError(response);
  //   return response;
  // }

  // async rmCompany(companyId: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const rmCompany = firebase.functions().httpsCallable('rmCompany');
  //   const response = (await rmCompany({ companyId })).data;
  //   this.handleResponseError(response);
  //   return response;
  // }

  // async addProspect(prospect: Partial<Prospect>): Promise<any> {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const addProspectCloud = firebase.functions().httpsCallable('addProspect');
  //   const response = (await addProspectCloud(prospect)).data;
  //   if (response.status !== 'error') this.setCurrentProspect(prospect.uid);
  //   this.handleResponseError(response);
  //   return response;
  // }

  // public async saveAnticipation(anticipation: Partial<Antecipacao>) {
  //   const saveAnticipation = firebase.functions().httpsCallable('saveAnticipation');
  //   const { data } = await saveAnticipation(anticipation);

  //   this.handleResponseError(data);

  //   return data;
  // }

  // getOnSigningUp(): boolean {
  //   return this.onSigningUp;
  // }

  // setOnSigningUp(state: boolean) {
  //   this.onSigningUp = state;
  // }

  // verifyEmail(): boolean {
  //   return firebase.auth().currentUser.emailVerified;
  // }

  // sendEmail() {
  //   firebase.auth().currentUser.sendEmailVerification();
  // }

  // async addPathXML(path: any, antecipacaoId: string) {
  //   // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //   const addPathXML = firebase.functions().httpsCallable('addPathXML');
  //   const response = (await addPathXML({ path, antecipacaoId })).data;
  //   //this.handleResponseError(response);
  //   return response;
  // }

  // async responseError(error: any) {
  //   await this.logout();
  //   alert(error.message || 'Ocorreu um erro inesperado');
  // }

  // async logout() {
  //   this.currentProspect.uid = '';
  //   //this.currentCompany.uid = '';
  //   await firebase.auth().signOut();
  //   this.router.navigateByUrl('/sign');
  // }

  formatMoney(value: number) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    const reais = value / 100;
    return formatter.format(reais);
  }
}
