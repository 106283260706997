import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-anticipation',
  templateUrl: './anticipation.component.html',
  styleUrls: ['./anticipation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnticipationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AnticipationComponent>,
  ) { }

  ngOnInit() {}

  handleClose(): void {
    this.dialogRef.close();
  }

}
