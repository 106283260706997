import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ErrorContainerComponent } from 'src/app/components/error-container/error-container.component'
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component'
import { FooterComponent } from 'src/app/components/footer/footer.component'
import { HeaderComponent } from 'src/app/components/header/header.component'
import { ListComponent } from 'src/app/components/list/list.component'
import { QuestionComponent } from 'src/app/components/question/question.component'
import { PipesModule } from 'src/app/pipes/pipes.module'
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    QuestionComponent,
    ErrorContainerComponent,
    ErrorMessageComponent,
    ListComponent
  ],
  imports: [CommonModule, IonicModule, PipesModule, MatMenuModule, MatButtonModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    QuestionComponent,
    ErrorContainerComponent,
    ErrorMessageComponent,
    ListComponent
  ]
})
export class SharedModule {}
