import { InjectionToken, Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatMoney' })
export class FormatMoneyPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return ''
    }
    value = this.clearStrings(value)
    const valor = +value

    const formattedMoney = (valor / 100).toLocaleString('pt-BR', {
      minimumFractionDigits: 2
    })

    return formattedMoney
  }

  private moneyFormatter(value: number) {
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  private clearStrings(txt?: string | number) {
    return txt.toString().replace(/[^a-zA-Z0-9]/g, '')
  }
}
