import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/auth.service';
import { JwtInfoService } from 'src/app/services/jwt-info.service';
import { User } from 'src/app/interface/user';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertController, ModalController } from '@ionic/angular';
import { DialogConfirmCreditCardComponent } from './dialog-confirm-credit-card/dialog-confirm-credit-card.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  someMethod() {
    this.trigger.openMenu();
  }
  emailVerificado = false;
  empresaAprovada = true;
  getInfoToken: User;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private jwthelp: JwtInfoService,
    public modalController: ModalController,
    public alert: AlertController,
    public modalCtrl: ModalController,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getInfoToken = this.jwthelp.jwtTokenCode();
    if (this.getInfoToken == undefined) {
      this.router.navigateByUrl('/sign');
    }
    this.validMail();
  }

  validMail() {
    if (this.getInfoToken.confirm_email == null) {
      this.emailVerificado = true;
    }
    return;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  openConfirmCreditCardDialog(): void{
    const dialogRef = this.dialog.open(DialogConfirmCreditCardComponent,
      {panelClass: 'my-dialog', disableClose: true});//classe do css la no component

     dialogRef.afterClosed().subscribe(r=> {
       console.log("fechou")
     })
    }

  async presentAlert(header?: string, message?: string, error?: boolean) {
    const alert = await this.alert.create({
      cssClass: 'basic-alert',
      header: 'Cartão de credito',
      message: `<img src="../../../assets/imgs/2cards.png" width="100" >
      <br/>`,
      inputs: [
        {
          name: 'checkbox3',
          type: 'checkbox',
          label: 'Aceito os termos',
          value: 'value3',
          handler: () => {
            console.log('Checkbox 3 selected');
          }
        }
      ],
      buttons: [
        {
          cssClass: 'btnAccept',
          text: 'Concordo',
          role: 'ok',
          handler: () => {
            if (!error) {
              console.log('teste card');
              this.router.navigateByUrl('/credit-card');
            }
          }
        },
        {
          text: 'Termos',
          role: 'cancel',
          handler: () => {
            window.open("https://termdocs-931757886655.s3-accesspoint.sa-east-1.amazonaws.com/ContraroCessa%CC%83oCartao.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEPH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXNhLWVhc3QtMSJIMEYCIQCjMYReWZmn13ZhElUXIe7SjUkLIwD5FlitCgPL%2BSpMJAIhAIzBOywlI40%2Bl8Nu%2FQGQ0nDfryvQu9znzhccYtPfjbdMKu0CCIr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMOTMxNzU3ODg2NjU1IgwrvUoWKqpnDRDoKoEqwQLhwM7%2FQZOoXzSOhpoaBkdj4AumkrdaZzzZOsQB92Z6lF8uajOmiUg2gF27Hm89R5pCMzv8SI0OUP5HkbJMzjk%2BTwox4XSB0Axri8%2BIguxZPc2xIVQQZ0Dp8WuHSQqadeu%2Bp45KK6pRei6GMtktW5MtR9i9MAeubRi7qsv9laU0lqRZwvcjrnJonyh7QEl4rFVQpyxEPzdzlxfkI1Eph%2B9YsioTn2rhDL0KgSjvh%2BW4Kz4iRSfAbKcMqnYr51wH70OpLU6hv07Gn2bMYk9Yd3poZmRrmaDWLQCVqUrAdiK4ampmUeqc%2BIBNLvbhHQf1eXyQzzCwcCtFx2EfIjB%2BAp1%2Fh4BBjWzJbYi2QoUpb5Gmmy0FObKsU0NuXf8smKyV3axEGyz4YnGz8APOQBFT%2B2UduPHc1sP%2Fx%2B%2FET14g46ysmEQwnvuvkgY6sgLt0Ps0qWtQaAXegMMHa1GKR5F6o42Kr6fasF%2BYM5Y4ZLVDUvE4UVSdOkBWaVWtvGvatMq%2BDJi%2Fteuz5VEE1e7p5%2FJI%2FN0N1bqg5hBR9pzo3x4qXjuChkpaWgMJQLcwdQTZJdWRkxJl%2FdBk8hJ7YCaPe3OVCm2TilaXtHsuJdukc2F6J7v7Yr0vjJ8hLUTLzHSUT79Byzg435jSn8hRs%2FWjVh1%2BL3Tai3YotqEVk%2F%2FR2ytAzevnGbJ8kiUQCoYo2qic07%2F2N0ybuuB7vYhl%2BkfDEOjxOSj0OHQKSmQSXsAV5F4HkYnG%2FpWF%2BmXx84hU8l%2BTnJV9MUlE3yqsN4Ow6N6f5X%2BcE89pLhnnr8uGQx6jPpXtIQZDV0DjSCmI%2B2%2Bd9fk231qKo0o7AAuNOy6RXu8xftw%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220405T084739Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIA5R4IZBS7ZPIA5G6T%2F20220405%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=42ea8ffd37109a47fa8e89fd1d211b47debadfbf5ce6d9021eb5372e675ac0b3", '_blank');
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            if (!error) {
              console.log('teste card');
              //this.router.navigateByUrl('/credit-card');
            }
          }
        }
      ]
    });
    await alert.present();
  }


  // Adicionar Termo
  async presentTerm(header?: string, message?: string, error?: boolean) {
    const alert = await this.alert.create({
      cssClass: 'basic-alert',
      header: 'Cartão de credito',
      message: `<img src="../../../assets/imgs/2cards.png" width="100" >
      <br/>`,
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Placeholder 1'
        }
      ],
      buttons: [
        {
          text: 'Concordo',
          role: 'close',
          handler: () => {
            this.presentAlert();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            if (!error) {
              console.log('teste card');
              //this.router.navigateByUrl('/credit-card');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async logout() {
    this.authService.logout();
    this.navigate('sign');
  }
}
