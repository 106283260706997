import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign',
    pathMatch: 'full'
  },
  {
    path: 'change-password/:token',
    loadChildren: async () =>
      await import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'sign',
    loadChildren: async () => await import('./pages/sign/sign.module').then(m => m.SignPageModule)
  },
  {
    path: 'cadastro-inicial',
    loadChildren: async () =>
      await import('./pages/cadastro-inicial/cadastro-inicial.module').then(m => m.CadastroInicialPageModule)
  },
    /*
   {
    path: 'cadastro-inicial/:userId/:companyId',
    loadChildren: async () =>
      await import('./pages/cadastro-inicial/cadastro-inicial.module').then(m => m.CadastroInicialPageModule),
    pathMatch: 'full'
  },

  {
    path: 'cadastro-inicial/:id/:viewCad',
    loadChildren: async () =>
      await import('./pages/cadastro-inicial/cadastro-inicial.module').then(m => m.CadastroInicialPageModule),
    pathMatch: 'full'
  }, */
  {
    path: 'companies',
    loadChildren: async () => await import('./pages/companys/companys.module').then(m => m.CompanysPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: async () => await import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'profile',
    loadChildren: async () => await import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'NFe',
    loadChildren: async () => await import('./pages/upload-nf/upload-nf.module').then(m => m.UploadNFPageModule)
  },
  {
    path: 'NFe/:id',
    loadChildren: async () => await import('./pages/upload-nf/upload-nf.module').then(m => m.UploadNFPageModule)
  },
  // {
  //   path: 'info-complementar/:id',
  //   loadChildren: async () =>
  //     await import('./pages/info-complementar/info-complementar.module').then(m => m.InfoComplementarPageModule)
  // },
  // {
  //   path: 'info-complementar',
  //   loadChildren: async () =>
  //     await import('./pages/info-complementar/info-complementar.module').then(m => m.InfoComplementarPageModule)
  // },
  {
    path: 'register',
    loadChildren: async () => await import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'home',
    loadChildren: async () => await import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'verify-email',
    loadChildren: async () =>
      await import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'verify-email/:token',
    loadChildren: async () =>
      await import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'sugerir-desktop',
    loadChildren: async () =>
      await import('./sugerir-desktop/sugerir-desktop.module').then(m => m.SugerirDesktopPageModule)
  },
  // {
  //   path: 'edit-empresa',
  //   loadChildren: () => import('./pages/edit-empresa/edit-empresa.module').then(m => m.EditEmpresaPageModule)
  // },
  {
    path: 'credit-card',
    loadChildren: () => import('./pages/credit-card/credit-card.module').then(m => m.CreditCardPageModule)
  },
  // {
  //   path: 'credit-card',
  //   loadChildren: async () =>
  //     await import('./pages/credit-receipt/credit-receipt.module').then(m => m.CreditReceiptPageModule)
  // },
  {
    path: '**',
    loadChildren: async () => await import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
