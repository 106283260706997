import { NgModule } from '@angular/core'

import { FormatCepPipe } from './format-cep.pipe'
import { FormatCnpjPipe } from './format-cnpj.pipe'
import { FormatCpfPipe } from './format-cpf.pipe'
import { FormatMoneyPipe } from './format-money.pipe'
import { FormatTelPipe } from './format-tel.pipe'

@NgModule({
  declarations: [FormatCnpjPipe, FormatTelPipe, FormatCepPipe, FormatCpfPipe, FormatMoneyPipe],
  imports: [],
  exports: [FormatCnpjPipe, FormatTelPipe, FormatCepPipe, FormatCpfPipe, FormatMoneyPipe]
})
export class PipesModule {}
