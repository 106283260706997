import { User } from './../models/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  LocalStorage: User[] = [];
  listStorageKey = '';
  defaulList = [];

  constructor() {
    this.listStorageKey = 'currentUser';
    this.LocalStorage = this.getData(this.listStorageKey) || this.defaulList;
  }

  getData(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  addItem(item: User) {
    this.LocalStorage.push(item);
    this.setData(this.listStorageKey, this.LocalStorage);
  }

  deleteList() {
    this.LocalStorage = [];
    this.saveList();
  }

  getEmail() {
    return this.getData(this.listStorageKey).map(item => item.email);
  }

  getList() {
    return this.getData(this.listStorageKey)
  }
  saveList() {
    this.setData(this.listStorageKey, this.LocalStorage);
  }
}
