export const activities = [
  {
    name: 'Cultivo de cereais'
  },
  {
    name: 'Cultivo de algodão herbáceo e de outras fibras de lavoura temporária'
  },
  {
    name: 'Cultivo de cana-de-açúcar'
  },
  {
    name: 'Cultivo de fumo'
  },
  {
    name: 'Cultivo de soja'
  },
  {
    name: 'Cultivo de oleaginosas de lavoura temporária'
  },
  {
    name: 'Cultivo de plantas de lavoura temporária não especificadas anteriormente'
  },
  {
    name: 'Horticultura'
  },
  {
    name: 'Cultivo de flores e plantas ornamentais'
  },
  {
    name: 'Cultivo de laranja'
  },
  {
    name: 'Cultivo de uva'
  },
  {
    name: 'Cultivo de frutas de lavoura permanente'
  },
  {
    name: 'Cultivo de café'
  },
  {
    name: 'Cultivo de cacau'
  },
  {
    name: 'Cultivo de plantas de lavoura permanente não especificadas anteriormente'
  },
  {
    name: 'Produção de sementes certificadas'
  },
  {
    name: 'Produção de mudas e outras formas de propagação vegetal'
  },
  {
    name: 'Criação de bovinos'
  },
  {
    name: 'Criação de outros animais de grande porte'
  },
  {
    name: 'Criação de caprinos e ovinos'
  },
  {
    name: 'Criação de suínos'
  },
  {
    name: 'Criação de aves'
  },
  {
    name: 'Criação de animais não especificados anteriormente'
  },
  {
    name: 'Atividades de apoio à agricultura'
  },
  {
    name: 'Atividades de apoio à pecuária'
  },
  {
    name: 'Atividades de pós-colheita'
  },
  {
    name: 'Caça e serviços relacionados'
  },
  {
    name: 'Produção florestal - florestas plantadas'
  },
  {
    name: 'Produção florestal - florestas nativas'
  },
  {
    name: 'Atividades de apoio à produção florestal'
  },
  {
    name: 'Pesca em água salgada'
  },
  {
    name: 'Pesca em água doce'
  },
  {
    name: 'Aquicultura em água salgada e salobra'
  },
  {
    name: 'Aquicultura em água doce'
  },
  {
    name: 'Extração de carvão mineral'
  },
  {
    name: 'Extração de petróleo e gás natural'
  },
  {
    name: 'Extração de minério de ferro'
  },
  {
    name: 'Extração de minério de alumínio'
  },
  {
    name: 'Extração de minério de estanho'
  },
  {
    name: 'Extração de minério de manganês'
  },
  {
    name: 'Extração de minério de metais preciosos'
  },
  {
    name: 'Extração de minerais radioativos'
  },
  {
    name: 'Extração de minerais metálicos não ferrosos não especificados anteriormente'
  },
  {
    name: 'Extração de pedra'
  },
  {
    name: 'Extração de minerais para fabricação de adubos'
  },
  {
    name: 'Extração e refino de sal marinho e sal-gema'
  },
  {
    name: 'Extração de gemas (pedras preciosas e semipreciosas)'
  },
  {
    name: 'Extração de minerais não metálicos não especificados anteriormente'
  },
  {
    name: 'Atividades de apoio à extração de petróleo e gás natural'
  },
  {
    name: 'Atividades de apoio à extração de minerais'
  },
  {
    name: 'Abate de reses'
  },
  {
    name: 'Abate de suínos'
  },
  {
    name: 'Fabricação de produtos de carne'
  },
  {
    name: 'Preservação do pescado e fabricação de produtos do pescado'
  },
  {
    name: 'Fabricação de conservas de frutas'
  },
  {
    name: 'Fabricação de conservas de legumes e outros vegetais'
  },
  {
    name: 'Fabricação de sucos de frutas'
  },
  {
    name: 'Fabricação de óleos vegetais em bruto'
  },
  {
    name: 'Fabricação de óleos vegetais refinados'
  },
  {
    name: 'Fabricação de margarina e outras gorduras vegetais e de óleos não comestíveis de animais'
  },
  {
    name: 'Preparação do leite'
  },
  {
    name: 'Fabricação de laticínios'
  },
  {
    name: 'Fabricação de sorvetes e outros gelados comestíveis'
  },
  {
    name: 'Beneficiamento de arroz e fabricação de produtos do arroz'
  },
  {
    name: 'Moagem de trigo e fabricação de derivados'
  },
  {
    name: 'Fabricação de farinha de mandioca e derivados'
  },
  {
    name: 'Fabricação de farinha de milho e derivados'
  },
  {
    name: 'Fabricação de amidos e féculas de vegetais e de óleos de milho'
  },
  {
    name: 'Fabricação de alimentos para animais'
  },
  {
    name: 'Moagem e fabricação de produtos de origem vegetal não especificados anteriormente'
  },
  {
    name: 'Fabricação de açúcar em bruto'
  },
  {
    name: 'Fabricação de açúcar refinado'
  },
  {
    name: 'Torrefação e moagem de café'
  },
  {
    name: 'Fabricação de produtos à base de café'
  },
  {
    name: 'Fabricação de produtos de panificação'
  },
  {
    name: 'Fabricação de biscoitos e bolachas'
  },
  {
    name: 'Fabricação de produtos derivados do cacau'
  },
  {
    name: 'Fabricação de massas alimentícias'
  },
  {
    name: 'Fabricação de especiarias'
  },
  {
    name: 'Fabricação de alimentos e pratos prontos'
  },
  {
    name: 'Fabricação de produtos alimentícios não especificados anteriormente'
  },
  {
    name: 'Fabricação de aguardentes e outras bebidas destiladas'
  },
  {
    name: 'Fabricação de vinho'
  },
  {
    name: 'Fabricação de malte'
  },
  {
    name: 'Fabricação de águas envasadas'
  },
  {
    name: 'Fabricação de refrigerantes e de outras bebidas não alcoólicas'
  },
  {
    name: 'Processamento industrial do fumo'
  },
  {
    name: 'Fabricação de produtos do fumo'
  },
  {
    name: 'Preparação e fiação de fibras de algodão'
  },
  {
    name: 'Preparação e fiação de fibras têxteis naturais'
  },
  {
    name: 'Fiação de fibras artificiais e sintéticas'
  },
  {
    name: 'Fabricação de linhas para costurar e bordar'
  },
  {
    name: 'Tecelagem de fios de algodão'
  },
  {
    name: 'Tecelagem de fios de fibras têxteis naturais'
  },
  {
    name: 'Tecelagem de fios de fibras artificiais e sintéticas'
  },
  {
    name: 'Fabricação de tecidos de malha'
  },
  {
    name: 'Acabamentos em fios'
  },
  {
    name: 'Fabricação de artefatos têxteis para uso doméstico'
  },
  {
    name: 'Fabricação de artefatos de tapeçaria'
  },
  {
    name: 'Fabricação de artefatos de cordoaria'
  },
  {
    name: 'Fabricação de tecidos especiais'
  },
  {
    name: 'Fabricação de outros produtos têxteis não especificados anteriormente'
  },
  {
    name: 'Confecção de roupas íntimas'
  },
  {
    name: 'Confecção de peças do vestuário'
  },
  {
    name: 'Confecção de roupas profissionais'
  },
  {
    name: 'Fabricação de acessórios do vestuário'
  },
  {
    name: 'Fabricação de meias'
  },
  {
    name: 'Fabricação de artigos do vestuário'
  },
  {
    name: 'Curtimento e outras preparações de couro'
  },
  {
    name: 'Fabricação de artigos para viagem'
  },
  {
    name: 'Fabricação de artefatos de couro não especificados anteriormente'
  },
  {
    name: 'Fabricação de calçados de couro'
  },
  {
    name: 'Fabricação de tênis de qualquer material'
  },
  {
    name: 'Fabricação de calçados de material sintético'
  },
  {
    name: 'Fabricação de calçados de materiais não especificados anteriormente'
  },
  {
    name: 'Fabricação de partes para calçados'
  },
  {
    name: 'Desdobramento de madeira'
  },
  {
    name: 'Fabricação de madeira laminada e de chapas de madeira compensada'
  },
  {
    name: 'Fabricação de estruturas de madeira e de artigos de carpintaria para construção'
  },
  {
    name: 'Fabricação de artefatos de tanoaria e de embalagens de madeira'
  },
  {
    name: 'Fabricação de artefatos de madeira, palha, cortiça, vime e material trançado não especificados anteriormente, exceto\nmóveis'
  },
  {
    name: 'Fabricação de celulose e outras pastas para a fabricação de papel'
  },
  {
    name: 'Fabricação de papel'
  },
  {
    name: 'Fabricação de cartolina e papel-cartão'
  },
  {
    name: 'Fabricação de embalagens de papel'
  },
  {
    name: 'Fabricação de embalagens de cartolina e papel-cartão'
  },
  {
    name: 'Fabricação de chapas e de embalagens de papelão ondulado'
  },
  {
    name: 'Fabricação de produtos de papel'
  },
  {
    name: 'Fabricação de produtos de papel para usos doméstico e higiênico-sanitário'
  },
  {
    name: 'Fabricação de produtos de pastas celulósicas'
  },
  {
    name: 'Impressão de jornais'
  },
  {
    name: 'Impressão de material de segurança'
  },
  {
    name: 'Impressão de materiais para outros usos'
  },
  {
    name: 'Serviços de pré-impressão'
  },
  {
    name: 'Serviços de acabamentos gráficos'
  },
  {
    name: 'Reprodução de materiais gravados em qualquer suporte'
  },
  {
    name: 'Coquerias'
  },
  {
    name: 'Fabricação de produtos do refino de petróleo'
  },
  {
    name: 'Fabricação de produtos derivados do petróleo'
  },
  {
    name: 'Fabricação de álcool'
  },
  {
    name: 'Fabricação de biocombustíveis'
  },
  {
    name: 'Fabricação de cloro e álcalis'
  },
  {
    name: 'Fabricação de intermediários para fertilizantes'
  },
  {
    name: 'Fabricação de adubos e fertilizantes'
  },
  {
    name: 'Fabricação de gases industriais'
  },
  {
    name: 'Fabricação de produtos químicos inorgânicos não especificados anteriormente'
  },
  {
    name: 'Fabricação de produtos petroquímicos básicos'
  },
  {
    name: 'Fabricação de intermediários para plastificantes'
  },
  {
    name: 'Fabricação de produtos químicos orgânicos não especificados anteriormente'
  },
  {
    name: 'Fabricação de resinas termoplásticas'
  },
  {
    name: 'Fabricação de resinas termofixas'
  },
  {
    name: 'Fabricação de elastômeros'
  },
  {
    name: 'Fabricação de fibras artificiais e sintéticas'
  },
  {
    name: 'Fabricação de defensivos agrícolas'
  },
  {
    name: 'Fabricação de desinfestantes domissanitários'
  },
  {
    name: 'Fabricação de sabões e detergentes sintéticos'
  },
  {
    name: 'Fabricação de produtos de limpeza e polimento'
  },
  {
    name: 'Fabricação de cosméticos'
  },
  {
    name: 'Fabricação de tintas'
  },
  {
    name: 'Fabricação de tintas de impressão'
  },
  {
    name: 'Fabricação de impermeabilizantes'
  },
  {
    name: 'Fabricação de adesivos e selantes'
  },
  {
    name: 'Fabricação de explosivos'
  },
  {
    name: 'Fabricação de aditivos de uso industrial'
  },
  {
    name: 'Fabricação de catalisadores'
  },
  {
    name: 'Fabricação de produtos químicos não especificados anteriormente'
  },
  {
    name: 'Fabricação de produtos farmoquímicos'
  },
  {
    name: 'Fabricação de medicamentos para uso humano'
  },
  {
    name: 'Fabricação de medicamentos para uso veterinário'
  },
  {
    name: 'Fabricação de preparações farmacêuticas'
  },
  {
    name: 'Fabricação de pneumáticos e de câmaras-de-ar'
  },
  {
    name: 'Reforma de pneumáticos usados'
  },
  {
    name: 'Fabricação de artefatos de borracha não especificados anteriormente'
  },
  {
    name: 'Fabricação de laminados planos e tubulares de material plástico'
  },
  {
    name: 'Fabricação de embalagens de material plástico'
  },
  {
    name: 'Fabricação de tubos e acessórios de material plástico para uso na construção'
  },
  {
    name: 'Fabricação de artefatos de material plástico não especificados anteriormente'
  },
  {
    name: 'Fabricação de vidro plano e de segurança'
  },
  {
    name: 'Fabricação de embalagens de vidro'
  },
  {
    name: 'Fabricação de artigos de vidro'
  },
  {
    name: 'Fabricação de cimento'
  },
  {
    name: 'Fabricação de artefatos de concreto'
  },
  {
    name: 'Fabricação de produtos cerâmicos refratários'
  },
  {
    name: 'Fabricação de produtos cerâmicos não refratários para uso estrutural na construção'
  },
  {
    name: 'Fabricação de produtos cerâmicos não refratários não especificados anteriormente'
  },
  {
    name: 'Aparelhamento e outros trabalhos em pedras'
  },
  {
    name: 'Fabricação de cal e gesso'
  },
  {
    name: 'Fabricação de produtos de minerais não metálicos não especificados anteriormente'
  },
  {
    name: 'Produção de ferro-gusa'
  },
  {
    name: 'Produção de ferroligas'
  },
  {
    name: 'Produção de semiacabados de aço'
  },
  {
    name: 'Produção de laminados planos de aço'
  },
  {
    name: 'Produção de laminados longos de aço'
  },
  {
    name: 'Produção de relaminados'
  },
  {
    name: 'Produção de tubos de aço com costura'
  },
  {
    name: 'Produção de outros tubos de ferro e aço'
  },
  {
    name: 'Metalurgia do alumínio e suas ligas'
  },
  {
    name: 'Metalurgia dos metais preciosos'
  },
  {
    name: 'Metalurgia do cobre'
  },
  {
    name: 'Metalurgia dos metais não ferrosos e suas ligas não especificados anteriormente'
  },
  {
    name: 'Fundição de ferro e aço'
  },
  {
    name: 'Fundição de metais não ferrosos e suas ligas'
  },
  {
    name: 'Fabricação de estruturas metálicas'
  },
  {
    name: 'Fabricação de esquadrias de metal'
  },
  {
    name: 'Fabricação de obras de caldeiraria pesada'
  },
  {
    name: 'Fabricação de tanques'
  },
  {
    name: 'Fabricação de caldeiras geradoras de vapor'
  },
  {
    name: 'Produção de forjados de aço e de metais não ferrosos e suas ligas'
  },
  {
    name: 'Produção de artefatos estampados de metal; metalurgia do pó'
  },
  {
    name: 'Serviços de usinagem'
  },
  {
    name: 'Fabricação de artigos de cutelaria'
  },
  {
    name: 'Fabricação de artigos de serralheria'
  },
  {
    name: 'Fabricação de ferramentas'
  },
  {
    name: 'Fabricação de equipamento bélico pesado'
  },
  {
    name: 'Fabricação de embalagens metálicas'
  },
  {
    name: 'Fabricação de produtos de trefilados de metal'
  },
  {
    name: 'Fabricação de artigos de metal para uso doméstico e pessoal'
  },
  {
    name: 'Fabricação de produtos de metal não especificados anteriormente'
  },
  {
    name: 'Fabricação de componentes eletrônicos'
  },
  {
    name: 'Fabricação de equipamentos de informática'
  },
  {
    name: 'Fabricação de periféricos para equipamentos de informática'
  },
  {
    name: 'Fabricação de equipamentos transmissores de comunicação'
  },
  {
    name: 'Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação'
  },
  {
    name: 'Fabricação de aparelhos de recepção'
  },
  {
    name: 'Fabricação de aparelhos e equipamentos de medida, teste e\ncontrole'
  },
  {
    name: 'Fabricação de cronômetros e relógios'
  },
  {
    name: 'Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação'
  },
  {
    name: 'Fabricação de equipamentos e instrumentos ópticos'
  },
  {
    name: 'Fabricação de mídias virgens'
  },
  {
    name: 'Fabricação de geradores'
  },
  {
    name: 'Fabricação de pilhas'
  },
  {
    name: 'Fabricação de baterias e acumuladores para veículos automotores'
  },
  {
    name: 'Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica'
  },
  {
    name: 'Fabricação de material elétrico para instalações em circuito de consumo'
  },
  {
    name: 'Fabricação de fios'
  },
  {
    name: 'Fabricação de lâmpadas e outros equipamentos de iluminação'
  },
  {
    name: 'Fabricação de fogões'
  },
  {
    name: 'Fabricação de aparelhos eletrodomésticos não especificados anteriormente'
  },
  {
    name: 'Fabricação de equipamentos e aparelhos elétricos não especificados anteriormente'
  },
  {
    name: 'Fabricação de motores e turbinas'
  },
  {
    name: 'Fabricação de equipamentos hidráulicos e pneumáticos'
  },
  {
    name: 'Fabricação de válvulas'
  },
  {
    name: 'Fabricação de compressores'
  },
  {
    name: 'Fabricação de equipamentos de transmissão para fins industriais'
  },
  {
    name: 'Fabricação de aparelhos e equipamentos para instalações térmicas'
  },
  {
    name: 'Fabricação de máquinas'
  },
  {
    name: 'Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial'
  },
  {
    name: 'Fabricação de aparelhos e equipamentos de ar condicionado'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para saneamento básico e ambiental'
  },
  {
    name: 'Fabricação de máquinas e equipamentos de uso geral não especificados anteriormente'
  },
  {
    name: 'Fabricação de tratores agrícolas'
  },
  {
    name: 'Fabricação de equipamentos para irrigação agrícola'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para a agricultura e pecuária'
  },
  {
    name: 'Fabricação de máquinas-ferramenta'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo'
  },
  {
    name: 'Fabricação de outras máquinas e equipamentos para uso na extração mineral'
  },
  {
    name: 'Fabricação de tratores'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para terraplenagem'
  },
  {
    name: 'Fabricação de máquinas para a indústria metalúrgica'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para as indústrias de alimentos'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para a indústria têxtil'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para as indústrias do vestuário'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para as indústrias de celulose'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para a indústria do plástico'
  },
  {
    name: 'Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente'
  },
  {
    name: 'Fabricação de automóveis'
  },
  {
    name: 'Fabricação de caminhões e ônibus'
  },
  {
    name: 'Fabricação de cabines'
  },
  {
    name: 'Fabricação de peças e acessórios para o sistema motor de veículos automotores'
  },
  {
    name: 'Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores'
  },
  {
    name: 'Fabricação de peças e acessórios para o sistema de freios de veículos automotores'
  },
  {
    name: 'Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores'
  },
  {
    name: 'Fabricação de material elétrico e eletrônico para veículos automotores'
  },
  {
    name: 'Fabricação de peças e acessórios para veículos automotores não especificados anteriormente'
  },
  {
    name: 'Recondicionamento e recuperação de motores para veículos automotores'
  },
  {
    name: 'Construção de embarcações e estruturas flutuantes'
  },
  {
    name: 'Construção de embarcações para esporte e lazer'
  },
  {
    name: 'Fabricação de locomotivas'
  },
  {
    name: 'Fabricação de peças e acessórios para veículos ferroviários'
  },
  {
    name: 'Fabricação de aeronaves'
  },
  {
    name: 'Fabricação de turbinas'
  },
  {
    name: 'Fabricação de veículos militares de combate'
  },
  {
    name: 'Fabricação de motocicletas'
  },
  {
    name: 'Fabricação de bicicletas e triciclos não motorizados'
  },
  {
    name: 'Fabricação de equipamentos de transporte não especificados anteriormente'
  },
  {
    name: 'Fabricação de móveis com predominância de madeira'
  },
  {
    name: 'Fabricação de móveis com predominância de metal'
  },
  {
    name: 'Fabricação de móveis de outros materiais'
  },
  {
    name: 'Fabricação de colchões'
  },
  {
    name: 'Lapidação de gemas e fabricação de artefatos de ourivesaria e joalheria'
  },
  {
    name: 'Fabricação de bijuterias e artefatos semelhantes'
  },
  {
    name: 'Fabricação de instrumentos musicais'
  },
  {
    name: 'Fabricação de artefatos para pesca e esporte'
  },
  {
    name: 'Fabricação de brinquedos e jogos recreativos'
  },
  {
    name: 'Fabricação de instrumentos e materiais para uso médico e odontológico e de artigos ópticos'
  },
  {
    name: 'Fabricação de escovas'
  },
  {
    name: 'Fabricação de equipamentos e acessórios para segurança e proteção pessoal e profissional'
  },
  {
    name: 'Fabricação de produtos diversos não especificados anteriormente'
  },
  {
    name: 'Manutenção e reparação de tanques'
  },
  {
    name: 'Manutenção e reparação de equipamentos eletrônicos e ópticos'
  },
  {
    name: 'Manutenção e reparação de máquinas e equipamentos elétricos'
  },
  {
    name: 'Manutenção e reparação de máquinas e equipamentos da indústria mecânica'
  },
  {
    name: 'Manutenção e reparação de veículos ferroviários'
  },
  {
    name: 'Manutenção e reparação de aeronaves'
  },
  {
    name: 'Manutenção e reparação de embarcações'
  },
  {
    name: 'Manutenção e reparação de equipamentos e produtos não especificados anteriormente'
  },
  {
    name: 'Instalação de máquinas e equipamentos industriais'
  },
  {
    name: 'Instalação de equipamentos não especificados anteriormente'
  },
  {
    name: 'Geração de energia elétrica'
  },
  {
    name: 'Transmissão de energia elétrica'
  },
  {
    name: 'Comércio atacadista de energia elétrica'
  },
  {
    name: 'Distribuição de energia elétrica'
  },
  {
    name: 'Produção de gás; processamento de gás natural; distribuição de combustíveis gasosos por redes urbanas'
  },
  {
    name: 'Produção e distribuição de vapor, água quente e ar\ncondicionado'
  },
  {
    name: 'Captação'
  },
  {
    name: 'Gestão de redes de esgoto'
  },
  {
    name: 'Atividades relacionadas a esgoto'
  },
  {
    name: 'Coleta de resíduos não perigosos'
  },
  {
    name: 'Coleta de resíduos perigosos'
  },
  {
    name: 'Tratamento e disposição de resíduos não perigosos'
  },
  {
    name: 'Tratamento e disposição de resíduos perigosos'
  },
  {
    name: 'Recuperação de materiais metálicos'
  },
  {
    name: 'Recuperação de materiais plásticos'
  },
  {
    name: 'Recuperação de materiais não especificados anteriormente'
  },
  {
    name: 'Descontaminação e outros serviços de gestão de resíduos'
  },
  {
    name: 'Incorporação de empreendimentos imobiliários'
  },
  {
    name: 'Construção de edifícios'
  },
  {
    name: 'Construção de rodovias e ferrovias'
  },
  {
    name: 'Construção de obras de arte especiais'
  },
  {
    name: 'Obras de urbanização - ruas'
  },
  {
    name: 'Obras para geração e distribuição de energia elétrica e para telecomunicações'
  },
  {
    name: 'Construção de redes de abastecimento de água'
  },
  {
    name: 'Construção de redes de transportes por dutos'
  },
  {
    name: 'Obras portuárias'
  },
  {
    name: 'Montagem de instalações industriais e de estruturas metálicas'
  },
  {
    name: 'Obras de engenharia civil não especificadas anteriormente'
  },
  {
    name: 'Demolição e preparação de canteiros de obras'
  },
  {
    name: 'Perfurações e sondagens'
  },
  {
    name: 'Obras de terraplenagem'
  },
  {
    name: 'Serviços de preparação do terreno não especificados anteriormente'
  },
  {
    name: 'Instalações elétricas'
  },
  {
    name: 'Instalações hidráulicas, de sistemas de ventilação e\nrefrigeração'
  },
  {
    name: 'Obras de instalações em construções não especificadas anteriormente'
  },
  {
    name: 'Obras de acabamento'
  },
  {
    name: 'Obras de fundações'
  },
  {
    name: 'Serviços especializados para construção não especificados anteriormente'
  },
  {
    name: 'Comércio a varejo e por atacado de veículos automotores'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de veículos automotores'
  },
  {
    name: 'Manutenção e reparação de veículos automotores'
  },
  {
    name: 'Comércio de peças e acessórios para veículos automotores'
  },
  {
    name: 'Comércio por atacado e a varejo de motocicletas'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de motocicletas'
  },
  {
    name: 'Manutenção e reparação de motocicletas'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de combustíveis'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de madeira'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de máquinas'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de eletrodomésticos'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de têxteis'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de produtos alimentícios'
  },
  {
    name: 'Representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente'
  },
  {
    name: 'Representantes comerciais e agentes do comércio de mercadorias em geral não especializado'
  },
  {
    name: 'Comércio atacadista de café em grão'
  },
  {
    name: 'Comércio atacadista de soja'
  },
  {
    name: 'Comércio atacadista de animais vivos'
  },
  {
    name: 'Comércio atacadista de leite e laticínios'
  },
  {
    name: 'Comércio atacadista de cereais e leguminosas beneficiados'
  },
  {
    name: 'Comércio atacadista de hortifrutigranjeiros'
  },
  {
    name: 'Comércio atacadista de carnes'
  },
  {
    name: 'Comércio atacadista de bebidas'
  },
  {
    name: 'Comércio atacadista de produtos do fumo'
  },
  {
    name: 'Comércio atacadista especializado em produtos alimentícios não especificados anteriormente'
  },
  {
    name: 'Comércio atacadista de produtos alimentícios em geral'
  },
  {
    name: 'Comércio atacadista de tecidos'
  },
  {
    name: 'Comércio atacadista de artigos do vestuário e acessórios'
  },
  {
    name: 'Comércio atacadista de calçados e artigos de viagem'
  },
  {
    name: 'Comércio atacadista de produtos farmacêuticos para uso humano e veterinário'
  },
  {
    name: 'Comércio atacadista de instrumentos e materiais para uso médico'
  },
  {
    name: 'Comércio atacadista de cosméticos'
  },
  {
    name: 'Comércio atacadista de artigos de escritório e de papelaria; livros, jornais e outras publicações'
  },
  {
    name: 'Comércio atacadista de equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente'
  },
  {
    name: 'Comércio atacadista de computadores'
  },
  {
    name: 'Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação'
  },
  {
    name: 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças'
  },
  {
    name: 'Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças'
  },
  {
    name: 'Comércio atacadista de máquinas e equipamentos para uso industrial; partes e peças'
  },
  {
    name: 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças'
  },
  {
    name: 'Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças'
  },
  {
    name: 'Comércio atacadista de máquinas, aparelhos e equipamentos não especificados anteriormente; partes e peças'
  },
  {
    name: 'Comércio atacadista de madeira e produtos derivados'
  },
  {
    name: 'Comércio atacadista de ferragens e ferramentas'
  },
  {
    name: 'Comércio atacadista de material elétrico'
  },
  {
    name: 'Comércio atacadista de cimento'
  },
  {
    name: 'Comércio atacadista especializado de materiais de construção não especificados anteriormente e de materiais de construção em geral'
  },
  {
    name: 'Comércio atacadista de combustíveis sólidos'
  },
  {
    name: 'Comércio atacadista de gás liquefeito de petróleo (GLP)'
  },
  {
    name: 'Comércio atacadista de defensivos agrícolas'
  },
  {
    name: 'Comércio atacadista de produtos químicos e petroquímicos'
  },
  {
    name: 'Comércio atacadista de produtos siderúrgicos e metalúrgicos'
  },
  {
    name: 'Comércio atacadista de papel e papelão em bruto e de embalagens'
  },
  {
    name: 'Comércio atacadista de resíduos e sucatas'
  },
  {
    name: 'Comércio atacadista especializado de outros produtos intermediários não especificados anteriormente'
  },
  {
    name: 'Comércio atacadista de mercadorias em geral'
  },
  {
    name: 'Comércio atacadista de mercadorias em geral'
  },
  {
    name: 'Comércio atacadista de mercadorias em geral'
  },
  {
    name: 'Comércio varejista de mercadorias em geral'
  },
  {
    name: 'Comércio varejista de mercadorias em geral, com\npredominância de produtos alimentícios - minimercados,\nmercearias e armazéns'
  },
  {
    name: 'Comércio varejista de mercadorias em geral'
  },
  {
    name: 'Comércio varejista de produtos de padaria'
  },
  {
    name: 'Comércio varejista de carnes e pescados - açougues e peixarias'
  },
  {
    name: 'Comércio varejista de bebidas'
  },
  {
    name: 'Comércio varejista de hortifrutigranjeiros'
  },
  {
    name: 'Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente; produtos do fumo'
  },
  {
    name: 'Comércio varejista de combustíveis para veículos automotores'
  },
  {
    name: 'Comércio varejista de lubrificantes'
  },
  {
    name: 'Comércio varejista de tintas e materiais para pintura'
  },
  {
    name: 'Comércio varejista de material elétrico'
  },
  {
    name: 'Comércio varejista de vidros'
  },
  {
    name: 'Comércio varejista de ferragens'
  },
  {
    name: 'Comércio varejista especializado de equipamentos e suprimentos de informática'
  },
  {
    name: 'Comércio varejista especializado de equipamentos de telefonia e comunicação'
  },
  {
    name: 'Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo'
  },
  {
    name: 'Comércio varejista especializado de móveis'
  },
  {
    name: 'Comércio varejista especializado de tecidos e artigos de cama'
  },
  {
    name: 'Comércio varejista especializado de instrumentos musicais e acessórios'
  },
  {
    name: 'Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico'
  },
  {
    name: 'Comércio varejista de artigos de uso doméstico não especificados anteriormente'
  },
  {
    name: 'Comércio varejista de livros'
  },
  {
    name: 'Comércio varejista de discos'
  },
  {
    name: 'Comércio varejista de artigos recreativos e esportivos'
  },
  {
    name: 'Comércio varejista de produtos farmacêuticos para uso humano e veterinário'
  },
  {
    name: 'Comércio varejista de cosméticos'
  },
  {
    name: 'Comércio varejista de artigos médicos e ortopédicos'
  },
  {
    name: 'Comércio varejista de artigos de óptica'
  },
  {
    name: 'Comércio varejista de artigos do vestuário e acessórios'
  },
  {
    name: 'Comércio varejista de calçados e artigos de viagem'
  },
  {
    name: 'Comércio varejista de jóias e relógios'
  },
  {
    name: 'Comércio varejista de gás liqüefeito de petróleo (GLP)'
  },
  {
    name: 'Comércio varejista de artigos usados'
  },
  {
    name: 'Comércio varejista de outros produtos novos não especificados anteriormente'
  },
  {
    name: 'Comércio ambulante e outros tipos de comércio varejista'
  },
  {
    name: 'Transporte ferroviário de carga'
  },
  {
    name: 'Transporte metroferroviário de passageiros'
  },
  {
    name: 'Transporte rodoviário coletivo de passageiros'
  },
  {
    name: 'Transporte rodoviário coletivo de passageiros'
  },
  {
    name: 'Transporte rodoviário de táxi'
  },
  {
    name: 'Transporte escolar'
  },
  {
    name: 'Transporte rodoviário coletivo de passageiros'
  },
  {
    name: 'Transporte rodoviário de carga'
  },
  {
    name: 'Transporte dutoviário'
  },
  {
    name: 'Trens turísticos'
  },
  {
    name: 'Transporte marítimo de cabotagem'
  },
  {
    name: 'Transporte marítimo de longo curso'
  },
  {
    name: 'Transporte por navegação interior de carga'
  },
  {
    name: 'Transporte por navegação interior de passageiros em linhas regulares'
  },
  {
    name: 'Navegação de apoio'
  },
  {
    name: 'Transporte por navegação de travessia'
  },
  {
    name: 'Transportes aquaviários não especificados anteriormente'
  },
  {
    name: 'Transporte aéreo de passageiros regular'
  },
  {
    name: 'Transporte aéreo de passageiros não regular'
  },
  {
    name: 'Transporte aéreo de carga'
  },
  {
    name: 'Transporte espacial'
  },
  {
    name: 'Armazenamento'
  },
  {
    name: 'Carga e descarga'
  },
  {
    name: 'Concessionárias de rodovias'
  },
  {
    name: 'Terminais rodoviários e ferroviários'
  },
  {
    name: 'Estacionamento de veículos'
  },
  {
    name: 'Atividades auxiliares dos transportes terrestres não especificadas anteriormente'
  },
  {
    name: 'Gestão de portos e terminais'
  },
  {
    name: 'Atividades de agenciamento marítimo'
  },
  {
    name: 'Atividades auxiliares dos transportes aquaviários não especificadas anteriormente'
  },
  {
    name: 'Atividades auxiliares dos transportes aéreos'
  },
  {
    name: 'Atividades relacionadas à organização do transporte de carga'
  },
  {
    name: 'Atividades de Correio'
  },
  {
    name: 'Atividades de malote e de entrega'
  },
  {
    name: 'Hotéis e similares'
  },
  {
    name: 'Outros tipos de alojamento não especificados anteriormente'
  },
  {
    name: 'Restaurantes e outros estabelecimentos de serviços de alimentação e bebidas'
  },
  {
    name: 'Serviços ambulantes de alimentação'
  },
  {
    name: 'Serviços de catering'
  },
  {
    name: 'Edição de livros'
  },
  {
    name: 'Edição de jornais'
  },
  {
    name: 'Edição de revistas'
  },
  {
    name: 'Edição de cadastros'
  },
  {
    name: 'Edição integrada à impressão de livros'
  },
  {
    name: 'Edição integrada à impressão de jornais'
  },
  {
    name: 'Edição integrada à impressão de revistas'
  },
  {
    name: 'Edição integrada à impressão de cadastros'
  },
  {
    name: 'Atividades de produção cinematográfica'
  },
  {
    name: 'Atividades de pós-produção cinematográfica'
  },
  {
    name: 'Distribuição cinematográfica'
  },
  {
    name: 'Atividades de exibição cinematográfica'
  },
  {
    name: 'Atividades de gravação de som e de edição de música'
  },
  {
    name: 'Atividades de rádio'
  },
  {
    name: 'Atividades de televisão aberta'
  },
  {
    name: 'Programadoras e atividades relacionadas à televisão por assinatura'
  },
  {
    name: 'Telecomunicações por fio'
  },
  {
    name: 'Telecomunicações sem fio'
  },
  {
    name: 'Telecomunicações por satélite'
  },
  {
    name: 'Operadoras de televisão por assinatura por cabo'
  },
  {
    name: 'Operadoras de televisão por assinatura por micro-ondas'
  },
  {
    name: 'Operadoras de televisão por assinatura por satélite'
  },
  {
    name: 'Outras atividades de telecomunicações'
  },
  {
    name: 'Desenvolvimento de programas de computador sob encomenda'
  },
  {
    name: 'Desenvolvimento e licenciamento de programas de computador customizáveis'
  },
  {
    name: 'Desenvolvimento e licenciamento de programas de computador não customizáveis'
  },
  {
    name: 'Consultoria em tecnologia da informação'
  },
  {
    name: 'Suporte técnico'
  },
  {
    name: 'Tratamento de dados'
  },
  {
    name: 'Portais'
  },
  {
    name: 'Agências de notícias'
  },
  {
    name: 'Outras atividades de prestação de serviços de informação não especificadas anteriormente'
  },
  {
    name: 'Banco Central'
  },
  {
    name: 'Bancos comerciais'
  },
  {
    name: 'Bancos múltiplos'
  },
  {
    name: 'Caixas econômicas'
  },
  {
    name: 'Crédito cooperativo'
  },
  {
    name: 'Bancos múltiplos'
  },
  {
    name: 'Bancos de investimento'
  },
  {
    name: 'Bancos de desenvolvimento'
  },
  {
    name: 'Agências de fomento'
  },
  {
    name: 'Crédito imobiliário'
  },
  {
    name: 'Sociedades de crédito'
  },
  {
    name: 'Sociedades de crédito ao microempreendedor'
  },
  {
    name: 'Bancos de câmbio e outras instituições de intermediação não monetária'
  },
  {
    name: 'Arrendamento mercantil'
  },
  {
    name: 'Sociedades de capitalização'
  },
  {
    name: 'Holdings de instituições financeiras'
  },
  {
    name: 'Holdings de instituições não financeiras'
  },
  {
    name: 'Outras sociedades de participação'
  },
  {
    name: 'Fundos de investimento'
  },
  {
    name: 'Sociedades de fomento mercantil - factoring'
  },
  {
    name: 'Securitização de créditos'
  },
  {
    name: 'Administração de consórcios para aquisição de bens e direitos'
  },
  {
    name: 'Outras atividades de serviços financeiros não especificadas anteriormente'
  },
  {
    name: 'Seguros de vida'
  },
  {
    name: 'Seguros não vida'
  },
  {
    name: 'Seguros-saúde'
  },
  {
    name: 'Resseguros'
  },
  {
    name: 'Previdência complementar fechada'
  },
  {
    name: 'Previdência complementar aberta'
  },
  {
    name: 'Planos de saúde'
  },
  {
    name: 'Administração de bolsas e mercados de balcão organizados'
  },
  {
    name: 'Atividades de intermediários em transações de títulos'
  },
  {
    name: 'Administração de cartões de crédito'
  },
  {
    name: 'Atividades auxiliares dos serviços financeiros não especificadas anteriormente'
  },
  {
    name: 'Avaliação de riscos e perdas'
  },
  {
    name: 'Corretores e agentes de seguros'
  },
  {
    name: 'Atividades auxiliares dos seguros'
  },
  {
    name: 'Atividades de administração de fundos por contrato ou\ncomissão'
  },
  {
    name: 'Atividades imobiliárias de imóveis próprios'
  },
  {
    name: 'Intermediação na compra'
  },
  {
    name: 'Gestão e administração da propriedade imobiliária'
  },
  {
    name: 'Atividades jurídicas'
  },
  {
    name: 'Cartórios'
  },
  {
    name: 'Atividades de contabilidade'
  },
  {
    name: 'Sedes de empresas e unidades administrativas locais'
  },
  {
    name: 'Atividades de consultoria em gestão empresarial'
  },
  {
    name: 'Serviços de arquitetura'
  },
  {
    name: 'Serviços de engenharia'
  },
  {
    name: 'Atividades técnicas relacionadas à arquitetura e engenharia'
  },
  {
    name: 'Testes e análises técnicas'
  },
  {
    name: 'Pesquisa e desenvolvimento experimental em ciências físicas e naturais'
  },
  {
    name: 'Pesquisa e desenvolvimento experimental em ciências sociais e humanas'
  },
  {
    name: 'Agências de publicidade'
  },
  {
    name: 'Agenciamento de espaços para publicidade'
  },
  {
    name: 'Atividades de publicidade não especificadas anteriormente'
  },
  {
    name: 'Pesquisas de mercado e de opinião pública'
  },
  {
    name: 'Design e decoração de interiores'
  },
  {
    name: 'Atividades fotográficas e similares'
  },
  {
    name: 'Atividades profissionais'
  },
  {
    name: 'Atividades veterinárias'
  },
  {
    name: 'Locação de automóveis sem condutor'
  },
  {
    name: 'Locação de meios de transporte'
  },
  {
    name: 'Aluguel de equipamentos recreativos e esportivos'
  },
  {
    name: 'Aluguel de fitas de vídeo'
  },
  {
    name: 'Aluguel de objetos do vestuário'
  },
  {
    name: 'Aluguel de objetos pessoais e domésticos não especificados anteriormente'
  },
  {
    name: 'Aluguel de máquinas e equipamentos agrícolas sem operador'
  },
  {
    name: 'Aluguel de máquinas e equipamentos para construção sem operador'
  },
  {
    name: 'Aluguel de máquinas e equipamentos para escritório'
  },
  {
    name: 'Aluguel de máquinas e equipamentos não especificados anteriormente'
  },
  {
    name: 'Gestão de ativos intangíveis não financeiros'
  },
  {
    name: 'Seleção e agenciamento de mão de obra'
  },
  {
    name: 'Locação de mão de obra temporária'
  },
  {
    name: 'Fornecimento e gestão de recursos humanos para terceiros'
  },
  {
    name: 'Agências de viagens'
  },
  {
    name: 'Operadores turísticos'
  },
  {
    name: 'Serviços de reservas e outros serviços de turismo não especificados anteriormente'
  },
  {
    name: 'Atividades de vigilância e segurança privada'
  },
  {
    name: 'Atividades de transporte de valores'
  },
  {
    name: 'Atividades de monitoramento de sistemas de segurança'
  },
  {
    name: 'Atividades de investigação particular'
  },
  {
    name: 'Serviços combinados para apoio a edifícios'
  },
  {
    name: 'Condomínios prediais'
  },
  {
    name: 'Limpeza em prédios e em domicílios'
  },
  {
    name: 'Imunização e controle de pragas urbanas'
  },
  {
    name: 'Atividades de limpeza não especificadas anteriormente'
  },
  {
    name: 'Atividades paisagísticas'
  },
  {
    name: 'Serviços combinados de escritório e apoio administrativo'
  },
  {
    name: 'Fotocópias'
  },
  {
    name: 'Atividades de teleatendimento'
  },
  {
    name: 'Atividades de organização de eventos'
  },
  {
    name: 'Atividades de cobrança e informações cadastrais'
  },
  {
    name: 'Envasamento e empacotamento sob contrato'
  },
  {
    name: 'Atividades de serviços prestados principalmente às empresas não especificadas anteriormente'
  },
  {
    name: 'Administração pública em geral'
  },
  {
    name: 'Regulação das atividades de saúde'
  },
  {
    name: 'Regulação das atividades econômicas'
  },
  {
    name: 'Relações exteriores'
  },
  {
    name: 'Defesa'
  },
  {
    name: 'Justiça'
  },
  {
    name: 'Segurança e ordem pública'
  },
  {
    name: 'Defesa Civil'
  },
  {
    name: 'Seguridade social obrigatória'
  },
  {
    name: 'Educação infantil - creche'
  },
  {
    name: 'Educação infantil - pré-escola'
  },
  {
    name: 'Ensino fundamental'
  },
  {
    name: 'Ensino médio'
  },
  {
    name: 'Educação superior - graduação'
  },
  {
    name: 'Educação superior - graduação e pós-graduação'
  },
  {
    name: 'Educação superior - pós-graduação e extensão'
  },
  {
    name: 'Educação profissional de nível técnico'
  },
  {
    name: 'Educação profissional de nível tecnológico'
  },
  {
    name: 'Atividades de apoio à educação'
  },
  {
    name: 'Ensino de esportes'
  },
  {
    name: 'Ensino de arte e cultura'
  },
  {
    name: 'Ensino de idiomas'
  },
  {
    name: 'Atividades de ensino não especificadas anteriormente'
  },
  {
    name: 'Atividades de atendimento hospitalar'
  },
  {
    name: 'Serviços móveis de atendimento a urgências'
  },
  {
    name: 'Serviços de remoção de pacientes'
  },
  {
    name: 'Atividades de atenção ambulatorial executadas por médicos e odontólogos'
  },
  {
    name: 'Atividades de serviços de complementação diagnóstica e terapêutica'
  },
  {
    name: 'Atividades de profissionais da área de saúde'
  },
  {
    name: 'Atividades de apoio à gestão de saúde'
  },
  {
    name: 'Atividades de atenção à saúde humana não especificadas anteriormente'
  },
  {
    name: 'Atividades de assistência a idosos'
  },
  {
    name: 'Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio'
  },
  {
    name: 'Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência\nquímica'
  },
  {
    name: 'Atividades de assistência social prestadas em residências coletivas e particulares'
  },
  {
    name: 'Serviços de assistência social sem alojamento'
  },
  {
    name: 'Artes cênicas'
  },
  {
    name: 'Criação artística'
  },
  {
    name: 'Gestão de espaços para artes cênicas'
  },
  {
    name: 'Atividades de bibliotecas e arquivos'
  },
  {
    name: 'Atividades de museus e de exploração, restauração artística\ne conservação de lugares e prédios históricos e atrações\nsimilares'
  },
  {
    name: 'Atividades de jardins botânicos'
  },
  {
    name: 'Atividades de exploração de jogos de azar e apostas'
  },
  {
    name: 'Gestão de instalações de esportes'
  },
  {
    name: 'Clubes sociais'
  },
  {
    name: 'Atividades de condicionamento físico'
  },
  {
    name: 'Atividades esportivas não especificadas anteriormente'
  },
  {
    name: 'Parques de diversão e parques temáticos'
  },
  {
    name: 'Atividades de recreação e lazer não especificadas\nanteriormente'
  },
  {
    name: 'Atividades de organizações associativas patronais e empresariais'
  },
  {
    name: 'Atividades de organizações associativas profissionais'
  },
  {
    name: 'Atividades de organizações sindicais'
  },
  {
    name: 'Atividades de associações de defesa de direitos sociais'
  },
  {
    name: 'Atividades de organizações religiosas'
  },
  {
    name: 'Atividades de organizações políticas'
  },
  {
    name: 'Atividades de organizações associativas ligadas à cultura\ne à arte'
  },
  {
    name: 'Atividades associativas não especificadas anteriormente'
  },
  {
    name: 'Reparação e manutenção de computadores e de equipamentos periféricos'
  },
  {
    name: 'Reparação e manutenção de equipamentos de comunicação'
  },
  {
    name: 'Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico'
  },
  {
    name: 'Reparação e manutenção de objetos e equipamentos pessoais e domésticos não especificados anteriormente'
  },
  {
    name: 'Lavanderias'
  },
  {
    name: 'Cabeleireiros e outras atividades de tratamento de beleza'
  },
  {
    name: 'Atividades funerárias e serviços relacionados'
  },
  {
    name: 'Atividades de serviços pessoais não especificadas anteriormente'
  },
  {
    name: 'Serviços domésticos'
  },
  {
    name: 'Organismos internacionais e outras instituições extraterritoriais'
  }
]
