import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { DialogConfirmCreditCardComponent } from './dialog-confirm-credit-card.component';

@NgModule({
  declarations: [DialogConfirmCreditCardComponent],
  imports: [
    CommonModule,
    MatCheckboxModule
  ]
})
export class DialogConfirmCreditCardModule { }
