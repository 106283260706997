import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatTel'
})
export class FormatTelPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return ''
    }
    value = this.clearStrings(value)
    let first: string
    let third: string
    let second: string

    if (value.length === 11) {
      first = value.substr(0, 2)
      third = value.substr(7, 4)
      second = value.substr(2, 5)
    } else {
      first = value.substr(0, 2)
      third = value.substr(6, 4)
      second = value.substr(2, 4)
    }

    const formattedTel = `(${first}) ${second}-${third}`

    return formattedTel
  }

  private clearStrings(txt?: string | number) {
    return txt.toString().replace(/[^a-zA-Z0-9]/g, '')
  }
}
