import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-credit-card',
  templateUrl: './dialog-confirm-credit-card.component.html',
  styleUrls: ['./dialog-confirm-credit-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogConfirmCreditCardComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmCreditCardComponent>,
    private readonly router: Router,
   ) { }

  ngOnInit() {}

  handleClose(): void {
    this.dialogRef.close();
  }

  handleAccept(): void{
    this.dialogRef.close();
    this.router.navigateByUrl('/credit-card');
  }

}
