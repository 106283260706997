import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardMachineService {
  apiUrl: string = environment.apiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  constructor(private readonly http: HttpClient) {}

  getList(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/card-machine/list`);
  }

  getListByName(name: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/card-machine/${name}`);
  }
}
