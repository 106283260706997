import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() companyId: string
  @Input() message: string

  constructor(private readonly modalController: ModalController) {}

  ngOnInit() {}

  async closeModal(opcao: string) {
    await this.modalController.dismiss(opcao)
  }
}
