export const banks = [
  {
    name: 'BCO DO BRASIL S.A.',
    code: 1,
    fullName: 'Banco do Brasil S.A.'
  },
  {
    name: 'BCO DA AMAZONIA S.A.',
    code: 3,
    fullName: 'BANCO DA AMAZONIA S.A.'
  },
  {
    name: 'BCO DO NORDESTE DO BRASIL S.A.',
    code: 4,
    fullName: 'Banco do Nordeste do Brasil S.A.'
  },
  {
    name: 'BNDES',
    code: 7,
    fullName: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL'
  },
  {
    name: 'CREDICOAMO',
    code: 10,
    fullName: 'CREDICOAMO CREDITO RURAL COOPERATIVA'
  },
  {
    name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
    code: 11,
    fullName: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A'
  },
  {
    name: 'BANCO INBURSA',
    code: 12,
    fullName: 'Banco Inbursa S.A.'
  },
  {
    name: 'STATE STREET BR S.A. BCO COMERCIAL',
    code: 14,
    fullName: 'STATE STREET BRASIL S.A. ? BANCO COMERCIAL'
  },
  {
    name: 'UBS BRASIL CCTVM S.A.',
    code: 15,
    fullName: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'CCM DESP TRÂNS SC E RS',
    code: 16,
    fullName: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI'
  },
  {
    name: 'BNY MELLON BCO S.A.',
    code: 17,
    fullName: 'BNY Mellon Banco S.A.'
  },
  {
    name: 'BCO TRICURY S.A.',
    code: 18,
    fullName: 'Banco Tricury S.A.'
  },
  {
    name: 'BCO BANESTES S.A.',
    code: 21,
    fullName: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO'
  },
  {
    name: 'BCO BANDEPE S.A.',
    code: 24,
    fullName: 'Banco Bandepe S.A.'
  },
  {
    name: 'BCO ALFA S.A.',
    code: 25,
    fullName: 'Banco Alfa S.A.'
  },
  {
    name: 'BANCO ITAÚ CONSIGNADO S.A.',
    code: 29,
    fullName: 'Banco Itaú Consignado S.A.'
  },
  {
    name: 'BCO SANTANDER (BRASIL) S.A.',
    code: 33,
    fullName: 'BANCO SANTANDER (BRASIL) S.A.'
  },
  {
    name: 'BCO BBI S.A.',
    code: 36,
    fullName: 'Banco Bradesco BBI S.A.'
  },
  {
    name: 'BCO DO EST. DO PA S.A.',
    code: 37,
    fullName: 'Banco do Estado do Pará S.A.'
  },
  {
    name: 'BCO CARGILL S.A.',
    code: 40,
    fullName: 'Banco Cargill S.A.'
  },
  {
    name: 'BCO DO ESTADO DO RS S.A.',
    code: 41,
    fullName: 'Banco do Estado do Rio Grande do Sul S.A.'
  },
  {
    name: 'BCO DO EST. DE SE S.A.',
    code: 47,
    fullName: 'Banco do Estado de Sergipe S.A.'
  },
  {
    name: 'CONFIDENCE CC S.A.',
    code: 60,
    fullName: 'Confidence Corretora de Câmbio S.A.'
  },
  {
    name: 'HIPERCARD BM S.A.',
    code: 62,
    fullName: 'Hipercard Banco Múltiplo S.A.'
  },
  {
    name: 'BANCO BRADESCARD',
    code: 63,
    fullName: 'Banco Bradescard S.A.'
  },
  {
    name: 'GOLDMAN SACHS DO BRASIL BM S.A',
    code: 64,
    fullName: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.'
  },
  {
    name: 'BCO ANDBANK S.A.',
    code: 65,
    fullName: 'Banco AndBank (Brasil) S.A.'
  },
  {
    name: 'BCO MORGAN STANLEY S.A.',
    code: 66,
    fullName: 'BANCO MORGAN STANLEY S.A.'
  },
  {
    name: 'BCO CREFISA S.A.',
    code: 69,
    fullName: 'Banco Crefisa S.A.'
  },
  {
    name: 'BRB - BCO DE BRASILIA S.A.',
    code: 70,
    fullName: 'BRB - BANCO DE BRASILIA S.A.'
  },
  {
    name: 'BCO. J.SAFRA S.A.',
    code: 74,
    fullName: 'Banco J. Safra S.A.'
  },
  {
    name: 'BCO ABN AMRO S.A.',
    code: 75,
    fullName: 'Banco ABN Amro S.A.'
  },
  {
    name: 'BCO KDB BRASIL S.A.',
    code: 76,
    fullName: 'Banco KDB do Brasil S.A.'
  },
  {
    name: 'BANCO INTER',
    code: 77,
    fullName: 'Banco Inter S.A.'
  },
  {
    name: 'HAITONG BI DO BRASIL S.A.',
    code: 78,
    fullName: 'Haitong Banco de Investimento do Brasil S.A.'
  },
  {
    name: 'BCO ORIGINAL DO AGRO S/A',
    code: 79,
    fullName: 'Banco Original do Agronegócio S.A.'
  },
  {
    name: 'B&T CC LTDA.',
    code: 80,
    fullName: 'B&T CORRETORA DE CAMBIO LTDA.'
  },
  {
    name: 'BANCOSEGURO S.A.',
    code: 81,
    fullName: 'BancoSeguro S.A.'
  },
  {
    name: 'BANCO TOPÁZIO S.A.',
    code: 82,
    fullName: 'BANCO TOPÁZIO S.A.'
  },
  {
    name: 'BCO DA CHINA BRASIL S.A.',
    code: 83,
    fullName: 'Banco da China Brasil S.A.'
  },
  {
    name: 'UNIPRIME NORTE DO PARANÁ - CC',
    code: 84,
    fullName: 'UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA'
  },
  {
    name: 'COOP CENTRAL AILOS',
    code: 85,
    fullName: 'Cooperativa Central de Crédito - Ailos'
  },
  {
    name: 'BANCO RANDON S.A.',
    code: 88,
    fullName: 'BANCO RANDON S.A.'
  },
  {
    name: 'CREDISAN CC',
    code: 89,
    fullName: 'CREDISAN COOPERATIVA DE CRÉDITO'
  },
  {
    name: 'CCCM UNICRED CENTRAL RS',
    code: 91,
    fullName: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S'
  },
  {
    name: 'BRK S.A. CFI',
    code: 92,
    fullName: 'BRK S.A. Crédito, Financiamento e Investimento'
  },
  {
    name: 'POLOCRED SCMEPP LTDA.',
    code: 93,
    fullName: 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT'
  },
  {
    name: 'BANCO FINAXIS',
    code: 94,
    fullName: 'Banco Finaxis S.A.'
  },
  {
    name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
    code: 95,
    fullName: 'Travelex Banco de Câmbio S.A.'
  },
  {
    name: 'BCO B3 S.A.',
    code: 96,
    fullName: 'Banco B3 S.A.'
  },
  {
    name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    code: 97,
    fullName: 'Credisis - Central de Cooperativas de Crédito Ltda.'
  },
  {
    name: 'CREDIALIANÇA CCR',
    code: 98,
    fullName: 'Credialiança Cooperativa de Crédito Rural'
  },
  {
    name: 'UNIPRIME CENTRAL CCC LTDA.',
    code: 99,
    fullName: 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.'
  },
  {
    name: 'PLANNER CV S.A.',
    code: 100,
    fullName: 'Planner Corretora de Valores S.A.'
  },
  {
    name: 'RENASCENCA DTVM LTDA',
    code: 101,
    fullName: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'XP INVESTIMENTOS CCTVM S/A',
    code: 102,
    fullName: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A'
  },
  {
    name: 'CAIXA ECONOMICA FEDERAL',
    code: 104,
    fullName: 'CAIXA ECONOMICA FEDERAL'
  },
  {
    name: 'LECCA CFI S.A.',
    code: 105,
    fullName: 'Lecca Crédito, Financiamento e Investimento S/A'
  },
  {
    name: 'BCO BOCOM BBM S.A.',
    code: 107,
    fullName: 'Banco Bocom BBM S.A.'
  },
  {
    name: 'PORTOCRED S.A. - CFI',
    code: 108,
    fullName: 'PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO'
  },
  {
    name: 'OLIVEIRA TRUST DTVM S.A.',
    code: 111,
    fullName: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.'
  },
  {
    name: 'MAGLIANO S.A. CCVM',
    code: 113,
    fullName: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios'
  },
  {
    name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
    code: 114,
    fullName: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP'
  },
  {
    name: 'ADVANCED CC LTDA',
    code: 117,
    fullName: 'ADVANCED CORRETORA DE CÂMBIO LTDA'
  },
  {
    name: 'BCO WESTERN UNION',
    code: 119,
    fullName: 'Banco Western Union do Brasil S.A.'
  },
  {
    name: 'BCO RODOBENS S.A.',
    code: 120,
    fullName: 'BANCO RODOBENS S.A.'
  },
  {
    name: 'BCO AGIBANK S.A.',
    code: 121,
    fullName: 'Banco Agibank S.A.'
  },
  {
    name: 'BCO BRADESCO BERJ S.A.',
    code: 122,
    fullName: 'Banco Bradesco BERJ S.A.'
  },
  {
    name: 'BCO WOORI BANK DO BRASIL S.A.',
    code: 124,
    fullName: 'Banco Woori Bank do Brasil S.A.'
  },
  {
    name: 'PLURAL BCO BM',
    code: 125,
    fullName: 'Plural S.A. Banco Múltiplo'
  },
  {
    name: 'BR PARTNERS BI',
    code: 126,
    fullName: 'BR Partners Banco de Investimento S.A.'
  },
  {
    name: 'CODEPE CVC S.A.',
    code: 127,
    fullName: 'Codepe Corretora de Valores e Câmbio S.A.'
  },
  {
    name: 'MS BANK S.A. BCO DE CÂMBIO',
    code: 128,
    fullName: 'MS Bank S.A. Banco de Câmbio'
  },
  {
    name: 'UBS BRASIL BI S.A.',
    code: 129,
    fullName: 'UBS Brasil Banco de Investimento S.A.'
  },
  {
    name: 'CARUANA SCFI',
    code: 130,
    fullName: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO'
  },
  {
    name: 'TULLETT PREBON BRASIL CVC LTDA',
    code: 131,
    fullName: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA'
  },
  {
    name: 'ICBC DO BRASIL BM S.A.',
    code: 132,
    fullName: 'ICBC do Brasil Banco Múltiplo S.A.'
  },
  {
    name: 'CRESOL CONFEDERAÇÃO',
    code: 133,
    fullName: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E'
  },
  {
    name: 'BGC LIQUIDEZ DTVM LTDA',
    code: 134,
    fullName: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'CONF NAC COOP CENTRAIS UNICRED',
    code: 136,
    fullName: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI'
  },
  {
    name: 'GET MONEY CC LTDA',
    code: 138,
    fullName: 'Get Money Corretora de Câmbio S.A.'
  },
  {
    name: 'INTESA SANPAOLO BRASIL S.A. BM',
    code: 139,
    fullName: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo'
  },
  {
    name: 'EASYNVEST - TÍTULO CV SA',
    code: 140,
    fullName: 'Easynvest - Título Corretora de Valores SA'
  },
  {
    name: 'BROKER BRASIL CC LTDA.',
    code: 142,
    fullName: 'Broker Brasil Corretora de Câmbio Ltda.'
  },
  {
    name: 'TREVISO CC S.A.',
    code: 143,
    fullName: 'Treviso Corretora de Câmbio S.A.'
  },
  {
    name: 'BEXS BCO DE CAMBIO S.A.',
    code: 144,
    fullName: 'BEXS BANCO DE CÂMBIO S/A'
  },
  {
    name: 'LEVYCAM CCV LTDA',
    code: 145,
    fullName: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.'
  },
  {
    name: 'GUITTA CC LTDA',
    code: 146,
    fullName: 'GUITTA CORRETORA DE CAMBIO LTDA.'
  },
  {
    name: 'FACTA S.A. CFI',
    code: 149,
    fullName: 'Facta Financeira S.A. - Crédito Financiamento e Investimento'
  },
  {
    name: 'ICAP DO BRASIL CTVM LTDA.',
    code: 157,
    fullName: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    name: 'CASA CREDITO S.A. SCM',
    code: 159,
    fullName: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor'
  },
  {
    name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
    code: 163,
    fullName: 'Commerzbank Brasil S.A. - Banco Múltiplo'
  },
  {
    name: 'BCO OLÉ CONSIGNADO S.A.',
    code: 169,
    fullName: 'BANCO OLÉ CONSIGNADO S.A.'
  },
  {
    name: 'BRL TRUST DTVM SA',
    code: 173,
    fullName: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'PEFISA S.A. - CFI',
    code: 174,
    fullName: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO'
  },
  {
    name: 'GUIDE',
    code: 177,
    fullName: 'Guide Investimentos S.A. Corretora de Valores'
  },
  {
    name: 'CM CAPITAL MARKETS CCTVM LTDA',
    code: 180,
    fullName: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'SOCRED SA - SCMEPP',
    code: 183,
    fullName: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P'
  },
  {
    name: 'BCO ITAÚ BBA S.A.',
    code: 184,
    fullName: 'Banco Itaú BBA S.A.'
  },
  {
    name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
    code: 188,
    fullName: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES'
  },
  {
    name: 'HS FINANCEIRA',
    code: 189,
    fullName: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS'
  },
  {
    name: 'SERVICOOP',
    code: 190,
    fullName: 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN'
  },
  {
    name: 'NOVA FUTURA CTVM LTDA.',
    code: 191,
    fullName: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    name: 'PARMETAL DTVM LTDA',
    code: 194,
    fullName: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'FAIR CC S.A.',
    code: 196,
    fullName: 'FAIR CORRETORA DE CAMBIO S.A.'
  },
  {
    name: 'STONE PAGAMENTOS S.A.',
    code: 197,
    fullName: 'Stone Pagamentos S.A.'
  },
  {
    name: 'BANCO BTG PACTUAL S.A.',
    code: 208,
    fullName: 'Banco BTG Pactual S.A.'
  },
  {
    name: 'BANCO ORIGINAL',
    code: 212,
    fullName: 'Banco Original S.A.'
  },
  {
    name: 'BCO ARBI S.A.',
    code: 213,
    fullName: 'Banco Arbi S.A.'
  },
  {
    name: 'BANCO JOHN DEERE S.A.',
    code: 217,
    fullName: 'Banco John Deere S.A.'
  },
  {
    name: 'BCO BS2 S.A.',
    code: 218,
    fullName: 'Banco BS2 S.A.'
  },
  {
    name: 'BCO CRÉDIT AGRICOLE BR S.A.',
    code: 222,
    fullName: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.'
  },
  {
    name: 'BCO FIBRA S.A.',
    code: 224,
    fullName: 'Banco Fibra S.A.'
  },
  {
    name: 'BANCO CIFRA',
    code: 233,
    fullName: 'Banco Cifra S.A.'
  },
  {
    name: 'BCO BRADESCO S.A.',
    code: 237,
    fullName: 'Banco Bradesco S.A.'
  },
  {
    name: 'BCO CLASSICO S.A.',
    code: 241,
    fullName: 'BANCO CLASSICO S.A.'
  },
  {
    name: 'BCO MÁXIMA S.A.',
    code: 243,
    fullName: 'Banco Máxima S.A.'
  },
  {
    name: 'BCO ABC BRASIL S.A.',
    code: 246,
    fullName: 'Banco ABC Brasil S.A.'
  },
  {
    name: 'BANCO INVESTCRED UNIBANCO S.A.',
    code: 249,
    fullName: 'Banco Investcred Unibanco S.A.'
  },
  {
    name: 'BCV',
    code: 250,
    fullName: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.'
  },
  {
    name: 'BEXS CC S.A.',
    code: 253,
    fullName: 'Bexs Corretora de Câmbio S/A'
  },
  {
    name: 'PARANA BCO S.A.',
    code: 254,
    fullName: 'PARANÁ BANCO S.A.'
  },
  {
    name: 'MONEYCORP BCO DE CÂMBIO S.A.',
    code: 259,
    fullName: 'MONEYCORP BANCO DE CÂMBIO S.A.'
  },
  {
    name: 'NU PAGAMENTOS S.A.',
    code: 260,
    fullName: 'Nu Pagamentos S.A.'
  },
  {
    name: 'BCO FATOR S.A.',
    code: 265,
    fullName: 'Banco Fator S.A.'
  },
  {
    name: 'BCO CEDULA S.A.',
    code: 266,
    fullName: 'BANCO CEDULA S.A.'
  },
  {
    name: 'BARI CIA HIPOTECÁRIA',
    code: 268,
    fullName: 'BARI COMPANHIA HIPOTECÁRIA'
  },
  {
    name: 'BCO HSBC S.A.',
    code: 269,
    fullName: 'BANCO HSBC S.A.'
  },
  {
    name: 'SAGITUR CC LTDA',
    code: 270,
    fullName: 'Sagitur Corretora de Câmbio Ltda.'
  },
  {
    name: 'IB CCTVM S.A.',
    code: 271,
    fullName: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'AGK CC S.A.',
    code: 272,
    fullName: 'AGK CORRETORA DE CAMBIO S.A.'
  },
  {
    name: 'CCR DE SÃO MIGUEL DO OESTE',
    code: 273,
    fullName: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel'
  },
  {
    name: 'MONEY PLUS SCMEPP LTDA',
    code: 274,
    fullName: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT'
  },
  {
    name: 'SENFF S.A. - CFI',
    code: 276,
    fullName: 'Senff S.A. - Crédito, Financiamento e Investimento'
  },
  {
    name: 'GENIAL INVESTIMENTOS CVM S.A.',
    code: 278,
    fullName: 'Genial Investimentos Corretora de Valores Mobiliários S.A.'
  },
  {
    name: 'CCR DE PRIMAVERA DO LESTE',
    code: 279,
    fullName: 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE'
  },
  {
    name: 'AVISTA S.A. CFI',
    code: 280,
    fullName: 'Avista S.A. Crédito, Financiamento e Investimento'
  },
  {
    name: 'CCR COOPAVEL',
    code: 281,
    fullName: 'Cooperativa de Crédito Rural Coopavel'
  },
  {
    name: 'RB CAPITAL INVESTIMENTOS DTVM LTDA.',
    code: 283,
    fullName: 'RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA'
  },
  {
    name: 'FRENTE CC LTDA.',
    code: 285,
    fullName: 'Frente Corretora de Câmbio Ltda.'
  },
  {
    name: 'CCR DE OURO',
    code: 286,
    fullName: 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO'
  },
  {
    name: 'CAROL DTVM LTDA.',
    code: 288,
    fullName: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.'
  },
  {
    name: 'DECYSEO CC LTDA.',
    code: 289,
    fullName: 'DECYSEO CORRETORA DE CAMBIO LTDA.'
  },
  {
    name: 'PAGSEGURO',
    code: 290,
    fullName: 'Pagseguro Internet S.A.'
  },
  {
    name: 'BS2 DTVM S.A.',
    code: 292,
    fullName: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'LASTRO RDV DTVM LTDA',
    code: 293,
    fullName: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    name: 'VISION S.A. CC',
    code: 296,
    fullName: 'VISION S.A. CORRETORA DE CAMBIO'
  },
  {
    name: 'VIPS CC LTDA.',
    code: 298,
    fullName: "Vip's Corretora de Câmbio Ltda."
  },
  {
    name: 'SOROCRED CFI S.A.',
    code: 299,
    fullName: 'SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.'
  },
  {
    name: 'BCO LA NACION ARGENTINA',
    code: 300,
    fullName: 'Banco de la Nacion Argentina'
  },
  {
    name: 'BPP IP S.A.',
    code: 301,
    fullName: 'BPP Instituição de Pagamento S.A.'
  },
  {
    name: 'PORTOPAR DTVM LTDA',
    code: 306,
    fullName: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.'
  },
  {
    name: 'TERRA INVESTIMENTOS DTVM',
    code: 307,
    fullName: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    name: 'CAMBIONET CC LTDA',
    code: 309,
    fullName: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.'
  },
  {
    name: 'VORTX DTVM LTDA.',
    code: 310,
    fullName: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.'
  },
  {
    name: 'AMAZÔNIA CC LTDA.',
    code: 313,
    fullName: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.'
  },
  {
    name: 'PI DTVM S.A.',
    code: 315,
    fullName: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'BCO BMG S.A.',
    code: 318,
    fullName: 'Banco BMG S.A.'
  },
  {
    name: 'OM DTVM LTDA',
    code: 319,
    fullName: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'BCO CCB BRASIL S.A.',
    code: 320,
    fullName: 'China Construction Bank (Brasil) Banco Múltiplo S/A'
  },
  {
    name: 'CREFAZ SCMEPP LTDA',
    code: 321,
    fullName: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT'
  },
  {
    name: 'CCR DE ABELARDO LUZ',
    code: 322,
    fullName: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz'
  },
  {
    name: 'MERCADO PAGO',
    code: 323,
    fullName: 'MERCADOPAGO.COM REPRESENTACOES LTDA.'
  },
  {
    name: 'CARTOS SCD S.A.',
    code: 324,
    fullName: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'ÓRAMA DTVM S.A.',
    code: 325,
    fullName: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'PARATI - CFI S.A.',
    code: 326,
    fullName: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.'
  },
  {
    name: 'QI SCD S.A.',
    code: 329,
    fullName: 'QI Sociedade de Crédito Direto S.A.'
  },
  {
    name: 'BANCO BARI S.A.',
    code: 330,
    fullName: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.'
  },
  {
    name: 'FRAM CAPITAL DTVM S.A.',
    code: 331,
    fullName: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    name: 'ACESSO SOLUCOES PAGAMENTO SA',
    code: 332,
    fullName: 'Acesso Soluções de Pagamento S.A.'
  },
  {
    name: 'BANCO DIGIO',
    code: 335,
    fullName: 'Banco Digio S.A.'
  },
  {
    name: 'BCO C6 S.A.',
    code: 336,
    fullName: 'Banco C6 S.A.'
  },
  {
    name: 'SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.',
    code: 340,
    fullName: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.'
  },
  {
    name: 'ITAÚ UNIBANCO S.A.',
    code: 341,
    fullName: 'ITAÚ UNIBANCO S.A.'
  },
  {
    name: 'CREDITAS SCD',
    code: 342,
    fullName: 'Creditas Sociedade de Crédito Direto S.A.'
  },
  {
    name: 'FFA SCMEPP LTDA.',
    code: 343,
    fullName: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.'
  },
  {
    name: 'BCO XP S.A.',
    code: 348,
    fullName: 'Banco XP S.A.'
  },
  {
    name: 'AL5 S.A. CFI',
    code: 349,
    fullName: 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO'
  },
  {
    name: 'CREHNOR LARANJEIRAS',
    code: 350,
    fullName: 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE'
  },
  {
    name: 'TORO CTVM LTDA',
    code: 352,
    fullName: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA'
  },
  {
    name: 'NECTON INVESTIMENTOS S.A CVM',
    code: 354,
    fullName: 'NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES'
  },
  {
    name: 'ÓTIMO SCD S.A.',
    code: 355,
    fullName: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'ZEMA CFI S/A',
    code: 359,
    fullName: 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A'
  },
  {
    name: 'TRINUS CAPITAL DTVM',
    code: 360,
    fullName: 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.'
  },
  { name: 'CIELO S.A.', code: 362, fullName: 'CIELO S.A.' },
  {
    name: 'SINGULARE CTVM S.A.',
    code: 363,
    fullName: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.'
  },
  {
    name: 'GERENCIANET',
    code: 364,
    fullName: 'GERENCIANET S.A.'
  },
  {
    name: 'SOLIDUS S.A. CCVM',
    code: 365,
    fullName: 'SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS'
  },
  {
    name: 'BCO SOCIETE GENERALE BRASIL',
    code: 366,
    fullName: 'BANCO SOCIETE GENERALE BRASIL S.A.'
  },
  {
    name: 'VITREO DTVM S.A.',
    code: 367,
    fullName: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.'
  },
  {
    name: 'BCO CSF S.A.',
    code: 368,
    fullName: 'Banco CSF S.A.'
  },
  {
    name: 'BCO MIZUHO S.A.',
    code: 370,
    fullName: 'Banco Mizuho do Brasil S.A.'
  },
  {
    name: 'WARREN CVMC LTDA',
    code: 371,
    fullName: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.'
  },
  {
    name: 'UP.P SEP S.A.',
    code: 373,
    fullName: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.'
  },
  {
    name: 'REALIZE CFI S.A.',
    code: 374,
    fullName: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.'
  },
  {
    name: 'BCO J.P. MORGAN S.A.',
    code: 376,
    fullName: 'BANCO J.P. MORGAN S.A.'
  },
  {
    name: 'BMS SCD S.A.',
    code: 377,
    fullName: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'BBC LEASING',
    code: 378,
    fullName: 'BBC LEASING S.A. - ARRENDAMENTO MERCANTIL'
  },
  {
    name: 'CECM COOPERFORTE',
    code: 379,
    fullName: 'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DE INSTIT'
  },
  {
    name: 'PICPAY',
    code: 380,
    fullName: 'PICPAY SERVICOS S.A.'
  },
  {
    name: 'BCO MERCEDES-BENZ S.A.',
    code: 381,
    fullName: 'BANCO MERCEDES-BENZ DO BRASIL S.A.'
  },
  {
    name: 'FIDUCIA SCMEPP LTDA',
    code: 382,
    fullName: 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L'
  },
  {
    name: 'JUNO',
    code: 383,
    fullName: 'BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.'
  },
  {
    name: 'GLOBAL SCM LTDA',
    code: 384,
    fullName: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO'
  },
  {
    name: 'BCO TOYOTA DO BRASIL S.A.',
    code: 387,
    fullName: 'Banco Toyota do Brasil S.A.'
  },
  {
    name: 'BCO MERCANTIL DO BRASIL S.A.',
    code: 389,
    fullName: 'Banco Mercantil do Brasil S.A.'
  },
  {
    name: 'BCO GM S.A.',
    code: 390,
    fullName: 'BANCO GM S.A.'
  },
  {
    name: 'CCR DE IBIAM',
    code: 391,
    fullName: 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM'
  },
  {
    name: 'BCO VOLKSWAGEN S.A',
    code: 393,
    fullName: 'Banco Volkswagen S.A.'
  },
  {
    name: 'BCO BRADESCO FINANC. S.A.',
    code: 394,
    fullName: 'Banco Bradesco Financiamentos S.A.'
  },
  {
    name: 'HUB PAGAMENTOS',
    code: 396,
    fullName: 'HUB PAGAMENTOS S.A'
  },
  {
    name: 'LISTO SCD S.A.',
    code: 397,
    fullName: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.'
  },
  {
    name: 'KIRTON BANK',
    code: 399,
    fullName: 'Kirton Bank S.A. - Banco Múltiplo'
  },
  {
    name: 'CORA SCD S.A.',
    code: 403,
    fullName: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'SUMUP SCD S.A.',
    code: 404,
    fullName: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'BÔNUSCRED SCD S.A.',
    code: 408,
    fullName: 'BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.'
  },
  {
    name: 'BCO CAPITAL S.A.',
    code: 412,
    fullName: 'BANCO CAPITAL S.A.'
  },
  {
    name: 'BCO SAFRA S.A.',
    code: 422,
    fullName: 'Banco Safra S.A.'
  },
  {
    name: 'BCO MUFG BRASIL S.A.',
    code: 456,
    fullName: 'Banco MUFG Brasil S.A.'
  },
  {
    name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
    code: 464,
    fullName: 'Banco Sumitomo Mitsui Brasileiro S.A.'
  },
  {
    name: 'BCO CAIXA GERAL BRASIL S.A.',
    code: 473,
    fullName: 'Banco Caixa Geral - Brasil S.A.'
  },
  {
    name: 'CITIBANK N.A.',
    code: 477,
    fullName: 'Citibank N.A.'
  },
  {
    name: 'BCO ITAUBANK S.A.',
    code: 479,
    fullName: 'Banco ItauBank S.A.'
  },
  {
    name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
    code: 487,
    fullName: 'DEUTSCHE BANK S.A. - BANCO ALEMAO'
  },
  {
    name: 'JPMORGAN CHASE BANK',
    code: 488,
    fullName: 'JPMorgan Chase Bank, National Association'
  },
  {
    name: 'ING BANK N.V.',
    code: 492,
    fullName: 'ING Bank N.V.'
  },
  {
    name: 'BCO LA PROVINCIA B AIRES BCE',
    code: 495,
    fullName: 'Banco de La Provincia de Buenos Aires'
  },
  {
    name: 'BCO CREDIT SUISSE S.A.',
    code: 505,
    fullName: 'Banco Credit Suisse (Brasil) S.A.'
  },
  {
    name: 'SENSO CCVM S.A.',
    code: 545,
    fullName: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A'
  },
  {
    name: 'BCO LUSO BRASILEIRO S.A.',
    code: 600,
    fullName: 'Banco Luso Brasileiro S.A.'
  },
  {
    name: 'BCO INDUSTRIAL DO BRASIL S.A.',
    code: 604,
    fullName: 'Banco Industrial do Brasil S.A.'
  },
  {
    name: 'BCO VR S.A.',
    code: 610,
    fullName: 'Banco VR S.A.'
  },
  {
    name: 'BCO PAULISTA S.A.',
    code: 611,
    fullName: 'Banco Paulista S.A.'
  },
  {
    name: 'BCO GUANABARA S.A.',
    code: 612,
    fullName: 'Banco Guanabara S.A.'
  },
  {
    name: 'OMNI BANCO S.A.',
    code: 613,
    fullName: 'Omni Banco S.A.'
  },
  {
    name: 'BANCO PAN',
    code: 623,
    fullName: 'Banco Pan S.A.'
  },
  {
    name: 'BCO C6 CONSIG',
    code: 626,
    fullName: 'BANCO C6 CONSIGNADO S.A.'
  },
  {
    name: 'SMARTBANK',
    code: 630,
    fullName: 'Banco Smartbank S.A.'
  },
  {
    name: 'BCO RENDIMENTO S.A.',
    code: 633,
    fullName: 'Banco Rendimento S.A.'
  },
  {
    name: 'BCO TRIANGULO S.A.',
    code: 634,
    fullName: 'BANCO TRIANGULO S.A.'
  },
  {
    name: 'BCO SOFISA S.A.',
    code: 637,
    fullName: 'BANCO SOFISA S.A.'
  },
  {
    name: 'BCO PINE S.A.',
    code: 643,
    fullName: 'Banco Pine S.A.'
  },
  {
    name: 'ITAÚ UNIBANCO HOLDING S.A.',
    code: 652,
    fullName: 'Itaú Unibanco Holding S.A.'
  },
  {
    name: 'BCO INDUSVAL S.A.',
    code: 653,
    fullName: 'BANCO INDUSVAL S.A.'
  },
  {
    name: 'BCO DIGIMAIS S.A.',
    code: 654,
    fullName: 'BANCO DIGIMAIS S.A.'
  },
  {
    name: 'BCO VOTORANTIM S.A.',
    code: 655,
    fullName: 'Banco Votorantim S.A.'
  },
  {
    name: 'BCO DAYCOVAL S.A',
    code: 707,
    fullName: 'Banco Daycoval S.A.'
  },
  {
    name: 'BCO OURINVEST S.A.',
    code: 712,
    fullName: 'Banco Ourinvest S.A.'
  },
  {
    name: 'BCO CETELEM S.A.',
    code: 739,
    fullName: 'Banco Cetelem S.A.'
  },
  {
    name: 'BCO RIBEIRAO PRETO S.A.',
    code: 741,
    fullName: 'BANCO RIBEIRAO PRETO S.A.'
  },
  {
    name: 'BANCO SEMEAR',
    code: 743,
    fullName: 'Banco Semear S.A.'
  },
  {
    name: 'BCO CITIBANK S.A.',
    code: 745,
    fullName: 'Banco Citibank S.A.'
  },
  {
    name: 'BCO MODAL S.A.',
    code: 746,
    fullName: 'Banco Modal S.A.'
  },
  {
    name: 'BCO RABOBANK INTL BRASIL S.A.',
    code: 747,
    fullName: 'Banco Rabobank International Brasil S.A.'
  },
  {
    name: 'BCO COOPERATIVO SICREDI S.A.',
    code: 748,
    fullName: 'BANCO COOPERATIVO SICREDI S.A.'
  },
  {
    name: 'SCOTIABANK BRASIL',
    code: 751,
    fullName: 'Scotiabank Brasil S.A. Banco Múltiplo'
  },
  {
    name: 'BCO BNP PARIBAS BRASIL S A',
    code: 752,
    fullName: 'Banco BNP Paribas Brasil S.A.'
  },
  {
    name: 'NOVO BCO CONTINENTAL S.A. - BM',
    code: 753,
    fullName: 'Novo Banco Continental S.A. - Banco Múltiplo'
  },
  {
    name: 'BANCO SISTEMA',
    code: 754,
    fullName: 'Banco Sistema S.A.'
  },
  {
    name: 'BOFA MERRILL LYNCH BM S.A.',
    code: 755,
    fullName: 'Bank of America Merrill Lynch Banco Múltiplo S.A.'
  },
  {
    name: 'BANCOOB',
    code: 756,
    fullName: 'BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB'
  },
  {
    name: 'BCO KEB HANA DO BRASIL S.A.',
    code: 757,
    fullName: 'BANCO KEB HANA DO BRASIL S.A.'
  }
]
