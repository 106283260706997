import { Component, isDevMode } from '@angular/core'
import { Router } from '@angular/router'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { Platform } from '@ionic/angular'

//import firebase from 'firebase/app'
//import 'firebase/auth'
import { User } from './models/user'
import { AuthenticationService } from '../app/services/auth.service'
import { DatabaseService } from './services/database.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  userAnonymous: any
  isLogged = false
  currentUser: User

  constructor(
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly router: Router,
    public dbService: DatabaseService,
    private authenticationService: AuthenticationService
  ) {
    this.initializeApp()
    this.authenticationService.currentUser.subscribe(x => (this.currentUser = x))
  }

  ngOnInit() {
    if (isDevMode()) {
      console.log('Development!')
    } else {
      console.log('Production!')
    }
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault()
      this.splashScreen.hide()
    })
     if (this.platform.is('mobile') || this.platform.is('mobileweb') || this.platform.is('cordova')) {
     return await this.router.navigateByUrl('/sugerir-desktop')
    }

    // if (!this.currentUser) {
    //   return await this.router.navigateByUrl('/sign')
    // }

    // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // firebase.auth().onAuthStateChanged(async user => {
    //   if (this.dbService.getOnSigningUp()) return

    //   if (!user) {
    //     return await this.router.navigateByUrl('/sign')
    //   }
    //   console.log('Autenticado')
    //   this.isLogged = true
    //   this.dbService.setCurrentProspect(user.uid)
    //   if (this.dbService.verifyEmail()) {
    //     return await this.router.navigateByUrl('/home')
    //   }
    //   this.router.navigateByUrl('/verify-email')
    // })
  }
}
