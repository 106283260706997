import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
// import { BrowserTracing } from "@sentry/tracing/dist/browser";

// Sentry.init({
//   dsn: "https://e9f01d2c8ac54f5f95641cf0aaa57766@o1201329.ingest.sentry.io/6326025",
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["localhost", "https://api.cliente-dev.kikaipay.com.br"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
