import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'src/app/services/local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class JwtInfoService {
  constructor(private jwtService: JwtHelperService, private localStorage: LocalStorageService) {}

  jwtTokenCode() {

    if (this.jwtService.decodeToken(this.localStorage.getList())) {

      const { email, id, name, confirmEmail, fullRegistration, cpf, telephone, status, role } =

        this.jwtService.decodeToken(localStorage.getItem('currentUser'));
      return {
        id: id,
        name: name,
        email: email,
        confirm_email: confirmEmail,
        full_registration: fullRegistration,
        cpf: cpf,
        telephone: telephone,
        status: status,
        role: role
      };
    }
  }
}
