import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatCpf'
})
export class FormatCpfPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return ''
    }

    value = this.clearStrings(value)

    const first = value.substr(0, 3)
    const second = value.substr(3, 3)
    const third = value.substr(6, 3)
    const fourth = value.substr(9, 2)

    const formattedCpf = `${first}.${second}.${third}-${fourth}
`
    return formattedCpf
  }

  private clearStrings(txt?: string | number) {
    return txt.toString().replace(/[^a-zA-Z0-9]/g, '')
  }
}
