import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatCep' })
export class FormatCepPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return ''
    }

    value = this.clearStrings(value)

    const first = value.substr(0, 5)
    const second = value.substr(5, 3)

    const formattedCep = `${first}-${second}`

    return formattedCep
  }

  clearStrings(txt?: string | number) {
    return txt.toString().replace(/[^a-zA-Z0-9]/g, '')
  }
}
