import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { ViewEncapsulation} from '@angular/core';
import { FormControl} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CardMachineService } from 'src/app/services/card-machine.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
  FormCardMachine = new FormControl();
  allCardMachine: object[] = [{}];

  constructor(
   public dialogRef: MatDialogRef<ModalComponent>,
   private readonly cardMachineService: CardMachineService,
  ) { }

  ngOnInit() {
   this.getListCardMachines()
  }

  handleClose(): void {
    this.dialogRef.close();
  }

  async getListCardMachines(){
    this.cardMachineService.getList().subscribe(async item =>{
    this.allCardMachine = item.data;
    console.log(this.allCardMachine);
   })
  }


}
